import React, { useState } from 'react';
import axios from 'axios';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendPasswordResetEmail = async (email) => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://sendpasswordresetemail-l4rhowcthq-uc.a.run.app', { email });

      // Lyckad begäran
      setStatusMessage('Återställningsmailet har skickat! Ett mail för att återställa ditt lösenord har skickats till den angivna e-postadressen. Vänligen kontrollera din inkorg och kontrollera även din skräppost.');
      setErrorMessage(null);
    } catch (error) {
      // Kontrollera om det är ett specifikt Axios-fel (ERR_BAD_RESPONSE)
      if (error.code === 'ERR_BAD_RESPONSE') {
        setErrorMessage('Mailadressen kunde inte hittas i systemet.');
      } else if (error.response && error.response.data) {
        // Kontrollera specifika felmeddelanden från backend
        const backendMessage = error.response.data.message;

        if (backendMessage === 'EMAIL_NOT_FOUND') {
          setErrorMessage('Denna mailadress kan inte hittas i databasen.');
        } else if (backendMessage === 'INVALID_EMAIL_FORMAT') {
          setErrorMessage('Vänligen ange en giltig e-postadress.');
        } else {
          // Generellt felmeddelande för okända fel
          setErrorMessage('Misslyckades med att skicka återställningsmailet. Vänligen försök igen senare.');
        }
      } else {
        // Om något annat fel inträffar som inte kommer från backend
        setErrorMessage('Något gick fel. Vänligen försök igen senare.');
      }
      setStatusMessage(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(email);
  };

  return (
    <div className='container w-full md:w-1/2 mx-auto p-8 mt-64 md:mt-20'>
      <h1 className='text-4xl dark:text-gray-100 font-extrabold'>Återställ ditt lösenord</h1>
      <form className='container' onSubmit={handleSubmit}>
        <label className='my-3 block mb-2 text-sm dark:text-white font-medium text-gray-900'>
          Vänligen ange din e-postadress
          <input 
            className='dark:bg-transparent dark:text-gray-400 dark:ring-gray-600 block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </label>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {statusMessage && <p className="text-green-800 bg-green-100 p-2 ">{statusMessage}</p>}
        <button 
          className='my-3 text-lg rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-700' 
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? 'Skickar...' : 'Skicka återställningsmail'}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
