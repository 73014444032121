// OrderDetails.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { useUserContext } from '../../../context/userContext';
import { CartContext } from '../../../context/cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faQuestionCircle } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';

const OrderDetails = ({ products }) => {
  const { currentUser } = useUserContext();
  const { orderId, displayName } = useParams(); // Hämta ordernumret från URL:en med useParams-hooken
  const [orderLines, setOrderLines] = useState([]);
  const [message, setMessage] = useState(''); // Ny state för meddelande
  const [helpMessage, setHelpMessage] = useState(false); // Ny state för meddelande

  const { addToCart } = useContext(CartContext);  // Hämta addToCart från CartContext
  const [missingProducts, setMissingProducts] = useState([]); // För produkter som saknas
  useEffect(() => {
    const fetchOrderLines = async () => {
      /* try {
        const response = await axios.get(`https://gastronomileverantoren.se/api/order/${orderId}/lines`, {
          headers: {
            'Content-Type': 'application/json'
          }
        }); */


      try {
        const response = await axios.get(`/api/order/${orderId}/lines`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const sortedOrderLines = response.data.value.sort((a, b) => a.lineId - b.lineId);
        setOrderLines(sortedOrderLines);
        
      } catch (error) {
        console.error('Error fetching order lines:', error);
      }
    };

    fetchOrderLines();
  }, [orderId]);

  if (orderLines.length === 0) {
    return <div>Loading...</div>;
  }

  // Ny funktion för att hantera "reorder" och meddelanden
  const handleReorder = () => {
    let notFoundProducts = []; // Lista för saknade produkter

    orderLines.forEach((line) => {
      const matchedProduct = products.find(product => product.inventoryNumber === line.inventory.id);

      if (matchedProduct) {
        addToCart(matchedProduct, null, null, line.quantity, null, true);  // isReorder: true
      } else {
        notFoundProducts.push(line.description); // Lägg till i listan om produkten inte hittas
      }
    });

    // Uppdatera meddelandet baserat på resultatet
    if (notFoundProducts.length === 0) {
      setMessage('Ombeställning lyckades. Alla produkter har lagts till i varukorgen.');
    } else {
      setMessage(`Ombeställning lyckades med kommentar: ${notFoundProducts.join(', ')} kunde inte hittas i systemet. Produkten/produkterna kan ha utgått.`);
      setMissingProducts(notFoundProducts); // Spara saknade produkter för framtida användning om det behövs
    }
  };

   // Funktion för att toggla helpMessage
   const toggleHelpMessage = () => {
    setHelpMessage(prev => !prev); // Växla mellan true och false
  };


  return (
    <div className='mt-24 pt-24 container mx-auto'>
      <nav className="flex p-5" aria-label="Breadcrumb">
        <ol className="inline-flex md:flex-row  text-sm justify-start dark:text-gray-200 md:mx-0 space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center"><Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/"><svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
          </svg>Hem</Link></li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${displayName}/user-profile`}>Profil</Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${displayName}/order-history`}>Orderhistorik</Link>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${currentUser}/order/${orderId}`}>Vald order {orderId}</Link>
            </div>
          </li>
        </ol>
      </nav>
      <div className="min-w-0 flex-1 mt-10 mb-5 ">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">Orderdetaljer för Order: {orderId}</h2>
      </div>
      <div className='flex justify-between md:flex-row flex-col items-center'>
      <div className='reorder flex'>
      <button 
        onClick={handleReorder} 
        className="bg-orange-500 text-white text-sm px-4 py-2 rounded-md hover:bg-blue-600">
        Lägg om beställning
      </button>
      <div className='help'>
      <FontAwesomeIcon onClick={toggleHelpMessage} icon={faQuestionCircle} size='xl' className="ms-2 text-gray-600 p-2 px-3 dark:text-gray-200 rounded-full" />
      
      </div>
      </div>
       {/* Rendera meddelande */}
       {message && (
        <div className="mt-4 p-4 border rounded-md bg-green-100 text-green-700">
          {message}
        </div>
      )}
      </div>  
      {helpMessage && (
        <div className="mt-4 p-4 border e rounded-md bg-orange-100 text-orange-700">
          <p>Att lägga om beställningen tar alla artiklar och deras antal från din nedanstående order och lägger till dem i din varukorg.
            Du kommer fortfarande kunna redigera din varukorg innan du slutför köpet. Om någon artikel inte hittas i systemet kommer du att få ett meddelande om detta.
          </p>
        </div>
      )}
      <div className="relative py-10 overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Radnummer</th>
              <th scope="col" className="px-6 py-3">Artikelnummer:</th>
              <th scope="col" className="px-6 py-3">Beskrivning:</th>
              <th scope="col" className="px-6 py-3">Antal:</th>
              <th scope="col" className="px-6 py-3">Styckpris (ex moms):</th>
            </tr>
          </thead>
          <tbody>
            {orderLines.map((line) => (
              <tr key={line.lineId} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {line.lineId}
                </th>
                <td className="px-6 py-4">{line.inventory.id}</td>
                <td className="px-6 py-4">{line.description}</td>
                <td className="px-6 py-4">{line.quantity} st</td>
                <td className="px-6 py-4">{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(line.unitPrice)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetails;
