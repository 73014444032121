import { Fragment, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faArrowUpRightFromSquare, faCircleUser, faUserCircle } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'
import {
  Disclosure,
  Menu,
  Transition,
} from '@headlessui/react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link,  useNavigate, useLocation } from 'react-router-dom'
import GastroLogga from "../imgs/ikon-frilagd.png";
import { useUserContext } from '../context/userContext'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TopHeader() {
  const { user, logout } = useUserContext();
  const { role} =  useUserContext();
  const navigate = useNavigate();
  const disclosureRef = useRef(null); // Skapar en ref för Disclosure
  
  const location = useLocation();  // Nytt för att lyssna på route-ändringar
  const handleLogout = () => {
    logout();
    navigate('/');
  }
  useEffect(() => {
    // Lyssna på varje route-ändring och stäng mobilmenyn vid förändring
    if (disclosureRef.current && disclosureRef.current.close) {
      disclosureRef.current.close();  // Använd ref för att stänga menyn
    }
  }, [location.pathname]);  // Kör varje gång location.pathname ändras

  return (
    <Disclosure as="nav" className="bg-gradient-to-br from-slate-800 to-gray-900 test w-full">
      {({ open, close }) => (
        <>
          <div className="">
            <div className="flex h-16 items-center justify-between w-full px-4 sm:px-6 lg:px-8">
              <div className="flex items-center">
                <div className="flex items-center flex-row text-white">
                  <Link className='flex items-center flex-row text-white' to="/">
                  <img
                    className="h-8 w-auto"
                    src={GastroLogga}
                    alt="GastronomiLeverantören"
                  />
                  
                  
                  <span className='ml-3 hidden md:block font-semibold'>GastronomiLeverantören</span></Link>
                </div>
                <div className="hidden sm:ml-6 lg:block">
                  <div className="flex space-x-4">
                    <Link to="/" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white" onClick={close}>
                      Start
                    </Link>
                    <Link to="/handla" className="rounded-md bg-gray-900 px-3 py-2 text-sm font-medium text-white">
                      Handla
                    </Link>
                  {/*   <Link
                    to="https://recept.gastronomileverantoren.se"
                    target="_blank" 
                    rel="noopener noreferrer"
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                     <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />
                      Recept <span className='text-xs'>(kommer snart)</span>
                    </Link>
                    <Link 
                     to="https://bildbank.gastronomileverantoren.se"
                     target="_blank" 
                     rel="noopener noreferrer"
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                     <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />
                      Bildbank
                    </Link>*/}
                    <Link to="/bli-aterforsaljare"
                     
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      Bli återförsäljare
                    </Link>
                    <Link to="/om-foretaget"
                      
                      className="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    >
                      Om Företaget
                    </Link>
                  
                  </div>
                </div>
              </div>
              <div className='flex flex-end w-auto'>
              {/* User management buttons */}
              <div className="flex items-center mr-5">
                {user ? (
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex  bg-transparent text-sm focus:outline-none focus:ring-1 focus:ring-gray-800 focus:ring-offset-4 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Användarmeny</span>
                        <p className="text-white">
                          <span className="hidden md:block">Inloggad som:</span> <span className="font-bold mr-3">{user.displayName}</span><FontAwesomeIcon size="xs" icon={faChevronDown} />
                        </p>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {role === 'admin' && (
                         <> <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/admin/promotional-management"
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Kampanjhantering
                              </Link>
                            )}
                          </Menu.Item>
                           <Menu.Item>
                           {({ active }) => (
                             <Link
                               to="/admin/highlight-management"
                               className={classNames(
                                 active ? 'bg-gray-100' : '',
                                 'block px-4 py-2 text-sm text-gray-700'
                               )}
                             >
                               Utvalda Produkter
                             </Link>
                           )}
                         </Menu.Item>
                         </>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <Link className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )} to={`/user/${user.displayName}/user-profile`}>Profil</Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link onClick={close} className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )} to={`/user/${user.displayName}/order-history`}>Orderhistorik</Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block w-full px-4 py-2 text-left text-sm text-gray-700'
                            )} onClick={handleLogout}>Logga ut</button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Link to="/login" className="text-white lg:block"><FontAwesomeIcon icon={faCircleUser} className='mr-3 ' />Logga in</Link>
                )}
              </div>

              <div className="mr-2 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <p className='text-sm text-white mr-3 font-semibold'>MENY</p>
                <Disclosure.Button className="relative  items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <AiOutlineClose className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <AiOutlineMenu className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                </div>
              </div>
              
            </div>
            
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              <Disclosure.Button
                as={Link}
                to="/handla"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Handla
              </Disclosure.Button>
              {/* <Disclosure.Button
                as={Link}
                to="https://recept.gastronomileverantoren.se"
                target="_blank" 
                rel="noopener noreferrer"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' /> Recept
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="https://bildbank.gastronomileverantoren.se"
                target="_blank" 
                rel="noopener noreferrer"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                <FontAwesomeIcon size="xs" icon={faArrowUpRightFromSquare} className='mr-3' />Bildbank
              </Disclosure.Button> */}
              <Disclosure.Button
                as={Link}
                to="/bli-aterforsaljare"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Bli återförsäljare
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/om-foretaget"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Om företaget
              </Disclosure.Button>
            
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
