import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

// Register font
Font.register({ family: 'Poppins', src: "https://fonts.gstatic.com/s/poppins/v1/TDTjCH39JjVycIF24TlO-Q.ttf" });
Font.register({ family: 'Open Sans Bold', src: 'https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzC3USBnSvpkopQaUR-2r7iU.ttf' });
Font.register({ family: 'Open Sans Normal', src: 'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf' });

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    fontFamily: 'Open Sans Normal'
  },
  section: {
    marginBottom: 10,
    fontFamily: 'Open Sans Normal',
  },
  title: {
    fontSize: 18,
    fontFamily: 'Poppins',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: 250,
    marginBottom: 10,
    objectFit: 'contain',
  },
  price: {
    fontFamily: 'Open Sans Bold',
    fontSize: 18,
  },
  label: {
    fontFamily: 'Open Sans Bold',
    fontSize: 14,
    borderBottom: '1px solid #e5e7eb',
    marginBottom: '5px'
  },
  ingredients: {
    fontFamily: 'Open Sans Normal',
    marginBottom: '5px'
  },
  nutritionalValue: {    fontFamily: 'Open Sans Normal',
    marginBottom: '5px'
  },
  text: {
    fontSize: 14,
    fontFamily: 'Open Sans Normal'
  },
});

// Function to convert basic HTML to React components for @react-pdf/renderer
const parseHtmlToPdf = (htmlString) => {
  return htmlString
    .replace(/<strong>(.*?)<\/strong>/g, (match, p1) => `<Text style="${JSON.stringify(styles.label)}">${p1}</Text>`)
    .replace(/<br\s*\/?>/g, () => '\n')
    .replace(/<\/?[^>]+(>|$)/g, ""); // Removes any other HTML tags
};

const ProductPDF = ({ product }) => {
  const frpAttribute = product.attributes.find(attribute => attribute.id === "FRP");
  const viktAttribute = product.attributes.find(attribute => attribute.id === "VIKT");
  const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(product.defaultPrice);

  const frpValue = frpAttribute ? frpAttribute.value : null;
  const viktValue = viktAttribute ? viktAttribute.value : null;
  const placeholderImageUrl = 'https://via.placeholder.com/250';

  const productImageUrl = product.productImages && product.productImages.length > 0
    ? `/image-proxy?url=${encodeURIComponent(product.productImages[0])}`
    : placeholderImageUrl;

  // Parse and sanitize HTML content
  const shortDescription = parseHtmlToPdf(product.body ? product.body.split('<strong>')[0].trim() : '');
  const ingredients = parseHtmlToPdf(product.body && product.body.includes('Ingredienser:')
    ? product.body.split('Ingredienser:')[1].split('<strong>')[0].trim()
    : '');
  const nutritionalValues = parseHtmlToPdf(product.body && product.body.includes('Näringsvärde per 100g:')
    ? product.body.split('Näringsvärde per 100g:')[1].split('<strong>')[0].trim()
    : '');

  // Process nutritional values
  const kcalIndex = nutritionalValues.indexOf('kcal') + 4; // 4 because we want to include "kcal" and the comma after it
  const firstValue = nutritionalValues.substring(0, kcalIndex).trim();
  const remainingContent = nutritionalValues.substring(kcalIndex).trim();
  const remainingValues = remainingContent.split(/(?<=g),/).map(value => value.trim());
  if (remainingValues[0].startsWith(',')) {
    remainingValues[0] = remainingValues[0].substring(1).trim();
  }
  const nutritionalValueArray = [
    { label: "Energi:", value: firstValue },
    { label: "Fett:", value: remainingValues[0] },
    { label: "- varav mättat fett:", value: remainingValues[1] },
    { label: "Kolhydrater:", value: remainingValues[2] },
    { label: "- varav sockerarter:", value: remainingValues[3] },
    { label: "Protein:", value: remainingValues[4] },
    { label: "Salt:", value: remainingValues[5] },
  ];
  const showNutritionalValues = nutritionalValueArray.some(nv => /\d/.test(nv.value));

  // Function to process ingredients with bold text for uppercase words
  const processIngredients = (text) => {
    return text.split(/\s+/).map((word, index) => {
      if (word === word.toUpperCase() && word.match(/[A-ZÅÄÖ]/)) {
        return <Text key={index} style={styles.label}>{word} </Text>;
      }
      return <Text key={index}>{word} </Text>;
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image fixed src={productImageUrl} style={styles.image} />
          <Text style={styles.title}>{product.description}</Text>
          <Text style={styles.text}>{product.inventoryNumber} - {viktValue} - {frpValue}</Text>
          <Text style={styles.price}>Pris: {formattedPrice}</Text>
          <Text style={styles.text}>{shortDescription}</Text>
        </View>
        {showNutritionalValues && (
          <View style={styles.section}>
            <Text style={styles.label}>Näringsvärde per 100g:</Text>
            {nutritionalValueArray.map((nv, index) => (
              <Text key={index} >
                <Text style={{fontFamily: 'Open Sans Bold', paddingRight: '10px'}}>{nv.label}</Text> 
                <Text style={{fontFamily: 'Open Sans Normal', paddingLeft: '10px'}}>{nv.value}</Text>
              </Text>
            ))}
          </View>
        )}
        {ingredients && (
          <View style={styles.section}>
            <Text style={styles.label}>Ingredienser:</Text>
            <Text style={styles.ingredients}>{processIngredients(ingredients)}</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default ProductPDF;
