import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProductContext } from '../context/ProductContext'; // Justera sökvägen efter behov

function Suppliers({ onClick }) {
  const navigate = useNavigate();
  const { supplierData } = useProductContext();

  // State för att lagra den valda leverantören
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  // Hantera klick på leverantör
  const handleSupplierClick = (supplierName) => {
    setSelectedSupplier(supplierName);
    navigate(`/supplier/${supplierName}`);
    onClick();
  };


  // Sortera leverantörerna i bokstavsordning
  const sortedSupplierData = [...supplierData].sort((a, b) =>
    a.supplierName.localeCompare(b.supplierName, 'sv', { sensitivity: 'base' })
  );


  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      <h2 className="sidebar-title m-3 font-bold">Leverantörer</h2>
      <ul className="space-y-2 pb-44 font-medium">
        {sortedSupplierData.map((supplier, index) => (
          <li key={index}  onClick={() => handleSupplierClick(supplier.supplierName)} className='border-b dark:border-b-gray-700'>
            <button 
              className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
             
            >
              <span className="ms-3">{supplier.supplierName}</span>
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Suppliers;
