import React, { createContext, useContext, useState, useEffect } from 'react';
import { authentication, db } from '../firebase/firebase';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import axios from 'axios';
import { encryptData, decryptData } from '../services/cryptoUtils';

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [role, setRole] = useState(null);
  const [discountInfo, setDiscountInfo] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const findCustomerByIdentifier = async (identifier) => {
    console.log('findCustomerByIdentifier triggered');
    const customersRef = collection(db, 'customers');
  
    try {
      // Sök med "number"-fältet
      const queryByNumber = query(customersRef, where('number', '==', identifier));
      const querySnapshotByNumber = await getDocs(queryByNumber);
  
      if (!querySnapshotByNumber.empty) {
        return querySnapshotByNumber.docs[0].data();
      }
  
      // Sök med gamla attribut
      const queryByOldNumber = query(customersRef, where('attributes', 'array-contains', {
        id: 'CUST02',
        value: identifier,
      }));
      const querySnapshotByOldNumber = await getDocs(queryByOldNumber);
  
      if (!querySnapshotByOldNumber.empty) {
        return querySnapshotByOldNumber.docs[0].data();
      }
  
      return null; // Om ingen match hittas
    } catch (error) {
      console.error('Error finding customer by identifier:', error);
      throw error;
    }
  };
  
  // Logga in användaren
  const login = async (identifier, password) => {
    console.log('Login triggered');
    setLoading(true); // Starta loadern innan processen påbörjas
    try {
      const isEmail = identifier.includes('@');
      let email = identifier;
  
      if (!isEmail) {
        const userDoc = await findCustomerByIdentifier(identifier);
        if (userDoc) {
          email = userDoc.mainContact.email;
        } else {
          throw new Error('Kundnummer kunde inte hittas.');
        }
      }
  
      const { user } = await signInWithEmailAndPassword(authentication, email, password);
      setUser(user); // Sätt användaren
      console.log('User logged in:', user);
  
      // Vänta på att data hämtas och bearbetas
      const customerData = await fetchCustomerData(user.uid);
      const discountPercent = await fetchDiscounts(customerData.id);
  
      const updatedData = { ...customerData, discount: discountPercent };
      sessionStorage.setItem('userData', encryptData(updatedData));
  
      setUserData(updatedData);
      setRole(updatedData.role);
      setSupplierId(updatedData.supplierId);
  
      console.log('Customer data and discounts loaded');
      await checkAndAssignAdminRole(user.uid, customerData); // Kontrollera adminstatus
    } catch (error) {
      console.error('Login error:', error);
      setError(mapAuthError(error));
    } finally {
      setLoading(false); // Sluta ladda först när allt är klart
    }
  };
  

  const mapAuthError = (error) => {
    switch (error.code) {
      case 'auth/invalid-credential':
        return 'Ogiltig e-postadress eller lösenord.';
      case 'auth/user-disabled':
        return 'Detta konto har inaktiverats.';
      case 'auth/user-not-found':
        return 'Ingen användare hittades med denna e-postadress.';
      case 'auth/wrong-password':
        return 'Fel lösenord.';
      default:
        return 'Ett oväntat fel inträffade. Försök igen senare.';
    }
  };
  

  // Hämta kunddata
  const fetchCustomerData = async (authUID) => {
    console.log('fechCustomerData triggered');
    const customersRef = collection(db, 'customers');
    const primaryQuery = query(customersRef, where('authUID', '==', authUID));
    const primarySnapshot = await getDocs(primaryQuery);

    if (!primarySnapshot.empty) {
      return { id: primarySnapshot.docs[0].id, ...primarySnapshot.docs[0].data() };
    }

    const allCustomersSnapshot = await getDocs(customersRef);
    for (const doc of allCustomersSnapshot.docs) {
      const customerData = doc.data();
      if (customerData.accounts?.some(account => account.authUID === authUID)) {
        return { id: doc.id, ...customerData };
      }
    }

    throw new Error('Kunddata kunde inte hittas för detta konto.');
  };

  // Kontrollera och tilldela adminstatus
  const checkAndAssignAdminRole = async (uid, customerData) => {
    console.log('admin uid in assign admin role:', uid)
    try {
      if (customerData.role !== 'admin') {
        setIsAdmin(false);
        return;
      }

      await axios.post('/api/setAdminRole', { uid }, { withCredentials: true });
      const response = await axios.get('/api/checkAdminRole', { params: { uid }, withCredentials: true });
      const currentUser = authentication.currentUser;
      if (currentUser) {
        await currentUser.getIdToken(true); // Force refresh token
      }
      console.log('Server response for admin check:', response.data);      
      setIsAdmin(response.data.isAdmin || false);
      console.log('user is admin, isAdmin value:', isAdmin);

    } catch (error) {
      console.error('Error checking and assigning admin role:', error);
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    console.log('isAdmin updated to:', isAdmin);
  }, [isAdmin]);

  useEffect(() => {
    console.log('loadin status', loading);
  }, [loading]);

  // Logga ut användaren
  const logout = async () => {
    try {
      await signOut(authentication);
      setUser(null);
      setUserData(null);
      setRole(null);
      setDiscountInfo(null);
      setSupplierId(null);
      setIsAdmin(false);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Hantera inloggningsstatus
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, async (userAuth) => {
      if (userAuth) {
        console.log('User detected in onAuthStateChanged');
        setLoading(true); // Börja ladda
        try {
          setUser(userAuth);
          const customerData = await fetchCustomerData(userAuth.uid);
          const discountPercent = await fetchDiscounts(customerData.id);
  
          const updatedData = { ...customerData, discount: discountPercent };
          sessionStorage.setItem('userData', encryptData(updatedData));
  
          setUserData(updatedData);
          setRole(updatedData.role);
          setSupplierId(updatedData.supplierId);
  
          console.log('Customer data and discounts loaded');
          await checkAndAssignAdminRole(userAuth.uid, customerData);
        } catch (error) {
          console.error('Error during user initialization:', error);
          setUser(null);
          setUserData(null);
          setRole(null);
          setIsAdmin(false);
        } finally {
          setLoading(false); // Sluta ladda
        }
      } else {
        setUser(null);
        setUserData(null);
        setRole(null);
        setIsAdmin(false);
        sessionStorage.removeItem('userData');
        setLoading(false); // Avsluta laddningen om ingen användare är inloggad
      }
    });
  
    return unsubscribe;
  }, []);
  

  // Hämta rabatter
  const fetchDiscounts = async (customerNumber) => {
    const discountsRef = collection(db, 'discounts');
    const discountsSnapshot = await getDocs(discountsRef);
    let discountPercent = 0;

    discountsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.customers?.some(customer => customer.customer === customerNumber)) {
        discountPercent = parseInt(data.discountCode, 10) || 0;
      }
    });

    return discountPercent;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userData,
        setUserData,
        role,
        supplierId,
        discountInfo,
        isAdmin,
        login,
        logout,
        loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
