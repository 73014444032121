import React, { useState } from 'react';
import { authentication } from '../firebase/firebase';
import { confirmPasswordReset } from 'firebase/auth'; // Importera confirmPasswordReset från Firebase
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const query = useQuery();
  const navigate = useNavigate();
  const oobCode = query.get('oobCode');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!oobCode) {
        alert('Ogiltig eller saknad kod för lösenordsåterställning.');
        return;
      }

      await confirmPasswordReset(authentication, oobCode, newPassword); // Använder confirmPasswordReset
      alert('Lösenordet har återställts framgångsrikt.');
      navigate('/login');
    } catch (error) {
      console.error('Fel vid återställning av lösenord:', error);
      alert('Misslyckades att återställa lösenordet. Vänligen försök igen.');
    }
  };

  return (
    <div className="container mx-auto p-8 mt-24 pt-24 max-w-md">
      <h1 className="text-2xl font-bold mb-4">Ange nytt lösenord</h1>
      <form onSubmit={handleSubmit}>
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Nytt lösenord
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </label>
        <button
          type="submit"
          className="mt-4 w-full py-2 bg-emerald-600 text-white font-semibold rounded-md hover:bg-emerald-700"
        >
          Sätt nytt lösenord
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
