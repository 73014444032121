import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

const RoomCanvas = ({
  background,
  artwork,
  productWidth,
  productHeight,
  name,
  customScaleFactor = 1,
  initialPosition = { x: 50, y: 50 }, // Initial position i procent
}) => {
  const containerRef = useRef(null);
  const [scaledDimensions, setScaledDimensions] = useState({ width: 0, height: 0 });
  const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });
  const [productPosition, setProductPosition] = useState(null); // Startar som null för att kunna initieras korrekt
  const [isHovered, setIsHovered] = useState(false);

  const updateScaleAndBounds = () => {
    if (containerRef.current) {
      const { offsetWidth: containerWidth, offsetHeight: containerHeight } = containerRef.current;

      // Skalfaktor baserat på bakgrundens dimensioner
      const widthScale = containerWidth / 100;
      const heightScale = containerHeight / 100;
      const finalScaleFactor = Math.min(widthScale, heightScale) * customScaleFactor;

      // Skala produkten
      const scaledWidth = productWidth * finalScaleFactor;
      const scaledHeight = productHeight * finalScaleFactor;

      setScaledDimensions({ width: scaledWidth, height: scaledHeight });

      // Beräkna bounds för draggable
      setBounds({
        left: 0,
        top: 0,
        right: containerWidth - scaledWidth,
        bottom: containerHeight - scaledHeight,
      });

      // Beräkna initial position i pixlar om productPosition inte redan är satt
      if (productPosition === null) {
        const initialX = (initialPosition.x / 100) * containerWidth - scaledWidth / 2;
        const initialY = (initialPosition.y / 100) * containerHeight - scaledHeight / 2;
        setProductPosition({ x: initialX, y: initialY });
      }
    }
  };

  useEffect(() => {
    updateScaleAndBounds();
    window.addEventListener("resize", updateScaleAndBounds);

    return () => {
      window.removeEventListener("resize", updateScaleAndBounds);
    };
  }, [productWidth, productHeight, customScaleFactor, initialPosition, productPosition]);

  const handleStop = (_, data) => {
    // Uppdatera produktens position efter att den har släppts
    setProductPosition({ x: data.x, y: data.y });
  };


  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        paddingTop: "56.25%",
        backgroundImage: `url(${background})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    >
      <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }}
  >
    <div className="absolute top-10 left-1/2 -translate-x-1/2">
      <h1 className="font-xl font-bold p-8 bg-gray-900 text-white">{name}</h1>
    </div>
      {/* Produktbild */}
      <Draggable
        bounds={bounds}
        position={productPosition} // Använd den aktuella positionen
        onStop={handleStop} // Uppdatera position när användaren släpper
      >
        <div
          style={{ position: "relative" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* Artificiell skugga */}
          <div
            style={{
              position: "absolute",
              top: `${scaledDimensions.height -8}px` + '',
              left: 0,
              width: `${scaledDimensions.width}px`,
              height: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderRadius: "50%",
              filter: "blur(4px)",
              zIndex: 0,
            }}
          />
          {/* Produktbild */}
          <img
            src={artwork}
            alt="Product"
            style={{
              width: `${scaledDimensions.width}px`,
              height: `${scaledDimensions.height}px`,
              position: "relative",
              zIndex: 1,
              cursor: "grab",
            }}
          />

          {/* Tooltips */}
          {isHovered && (
            <>
              {/* Tooltip för höjd */}
              <div
                style={{
                  position: "absolute",
                  left: `-70px`,
                  top: `${scaledDimensions.height / 2}px`,
                  transform: "translateY(-50%)",
                  backgroundColor: "black",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  fontSize: "12px",
                  zIndex: 10,
                }}
              >
                Höjd: {productHeight}cm
              </div>
              {/* Tooltip för bredd */}
              <div
                style={{
                  position: "absolute",
                  left: `${scaledDimensions.width / 2}px`,
                  top: `${scaledDimensions.height + 10}px`,
                  transform: "translateX(-50%)",
                  backgroundColor: "black",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "4px",
                  fontSize: "12px",
                  zIndex: 10,
                }}
              >
                Bredd: {productWidth}cm
              </div>
            </>
          )}
        </div>
      </Draggable>
      </div>
    </div>
  );
};

export default RoomCanvas;
