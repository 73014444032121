import React, { useContext, useState } from 'react';
import { CartContext } from '../context/cart';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareDashed, faXmark } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid'
import { faCheckCircle, faTrash } from '@awesome.me/kit-e012a9aa4e/icons/classic/regular';


const CartPage = () => {
  const {
    cartItems,
    addToCart,
    removeFromCart,
    isCombinedDelivery,
    setIsCombinedDelivery,
    getBestItemsTotal,
    getNonBestItemsTotal,
    getTotal,
    totalDiscount,
    clearCart,
    removeItemCompletely,
    getShippingStatus,
  } = useContext(CartContext);

  const placeholderImageUrl = 'https://via.placeholder.com/350';
  const [selectMode, setSelectMode] = useState(false); // För att aktivera/avaktivera markering av produkter
  const [selectedItems, setSelectedItems] = useState([]); // För att hålla koll på valda produkter

  // Filter for "BEST" items
  const bestItems = cartItems.filter(item =>
    item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "1")
  );

  // Filter for non-"BEST" items (inklusive de som inte har "BEST" attributet alls)
  const nonBestItems = cartItems.filter(item =>
    item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "0") ||
    !item.attributes.some(attribute => attribute.id === "BEST")
  );

  // Använd korrekt total för fraktfri-beräkning beroende på om samleverans är aktiv
  const cartTotalForShipping = isCombinedDelivery ? getTotal() : getNonBestItemsTotal();
  const { amountRemaining, percentage, isFreeShipping } = getShippingStatus(cartTotalForShipping);

  const formattedAmountRemaining = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amountRemaining);


  // Hantera aktivering/inaktivering av markering av produkter
  const toggleSelectMode = () => {
    setSelectMode(!selectMode);
    setSelectedItems([]); // Rensa markerade produkter när läget ändras
  };

  // Hantera val av produkt
  const toggleSelectItem = (item) => {
    if (selectedItems.includes(item.inventoryNumber)) {
      setSelectedItems(selectedItems.filter(id => id !== item.inventoryNumber));
    } else {
      setSelectedItems([...selectedItems, item.inventoryNumber]);
    }
  };

  // Avmarkera alla valda produkter
  const clearSelectedItems = () => {
    setSelectedItems([]);
  };

  // Ta bort alla valda produkter
    // Ta bort alla valda produkter helt och hållet
  const removeSelectedItems = () => {
    selectedItems.forEach(id => {
      const itemToRemove = cartItems.find(item => item.inventoryNumber === id);
      removeItemCompletely(itemToRemove); // Anropa funktionen för att helt ta bort produkten
    });
    setSelectedItems([]); // Rensa markeringarna efter borttagning
  };


  return (
    <div className="bg-gray-50 dark:bg-gray-800 ">
        
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-24 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <nav className="flex py-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to={`/varukorg`}>Varukorg</Link>
                        </div>
                    </li>
                </ol>
            </nav>
        <h1 className="text-4xl mt-10 text-bold dark:text-white font-bold border-b pb-6 border-gray-200">Varukorg</h1>
        <div className="flex flex-col my-4 md:mt-4 md:flex-row justify-center  items-center gap-5 md:min-h-20">
       
          <label className="flex items-center text-gray-900 dark:text-white bg-white p-2 px-5 border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700">
           
            <input
              type="checkbox"
              className="mr-2 peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800"
              checked={selectMode}
              onChange={toggleSelectMode}
            />
            Markera produkter
          </label>
         
         
            <div className="space-x-4 mx-auto flex items-center justify-center flex-col md:flex-row align gap-2">
              <button
                onClick={clearSelectedItems}
                className="bg-gray-400 text-white py-2 px-4 rounded-md hover:bg-gray-500  text-center"
              >
                 <FontAwesomeIcon icon={faSquareDashed} className="mr-2" />
                Avmarkera produkter
              </button>
              <button
                onClick={removeSelectedItems}
                className="bg-red-600 text-white py-2 px-4  m-0 rounded-md hover:bg-red-700 text-center"
              >
                  <FontAwesomeIcon icon={faXmark} className="mr-2" />
                Ta bort valda produkter
              </button>
            </div>
        
           <button onClick={clearCart} className="bg-gray-800 text-white py-2 px-4  rounded-md hover:bg-gray-500">  <FontAwesomeIcon icon={faTrash} className="mr-2" />Töm varukorg</button>

        </div>
<div className='flex flex-col lg:flex-row lg:space-x-8   '>
        <div className="mt-10 lg:w-2/3 w-full ">
                {/* Knapp för att aktivera markering av produkter */}
      
          {/* Om samleverans är vald, rendera alla produkter i en lista */}
          {isCombinedDelivery ? (
            <div className="mt-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
              <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                {[...nonBestItems, ...bestItems].map((item) => {
                  const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
                  const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
                  const radTotal = itemPrice * item.quantity;
                  return (
                    <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                         {selectMode && (
                        <input
                          type="checkbox"
                          className="mr-2 peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-800 checked:bg-slate-800 checked:border-slate-800"
                          checked={selectedItems.includes(item.inventoryNumber)}
                          onChange={() => toggleSelectItem(item)}
                        />
                      )}
                      <div className="flex-shrink-0">
                        <img
                          src={productImageUrl}
                          alt={item.imageAlt}
                          className="w-12 object-contain h-12 rounded-md"
                        />
                      </div>
                      <div className="ml-6 flex-1 flex flex-col justify-between">
                        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                          <div className="flex justify-between w-full">
                            <h3 className="text-sm w-full">
                              <Link to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`} className="font-medium text-gray-700 dark:text-white hover:text-gray-800">
                                {item.description}
                              </Link>
                            </h3>
                            <div class="flex flex-col">
                            <p className="ml-4 text-sm font-medium">
                              {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice)}
                            </p>
                            <p className="ml-4 text-sm font-medium">
                              {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice)}
                            </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                          <div className="relative inline-block text-left">
                            <div className="flex items-center space-x-2 group">
                              <button
                                type="button"
                                className="px-4 py-2 bg-red-400/70 text-white text-xs font-bold  transform active:scale-75 transition-transform uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                onClick={() => removeFromCart(item)}
                              >
                                -
                              </button>
                              <span className="text-sm font-medium text-gray-900 dark:text-white border-1">
                                {item.quantity}
                              </span>
                              <button
                                type="button"
                                className="px-4 py-2 bg-gradient-to-r from-green-400 to-green-500  transform active:scale-75 transition-transformtext-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none"
                                onClick={() => addToCart(item)}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <>
              {/* Render non-"BEST" items separat om samleverans inte är vald */}
              <div className="bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
                <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                  {nonBestItems.map((item) => {
                    const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
                    const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
                    const radTotal = itemPrice * item.quantity;
                    return (
                      <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                         {selectMode && (
                        <input
                          type="checkbox"
                          className="mr-2 peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border-2 border-slate-800 checked:bg-slate-800 checked:border-slate-800"
                          checked={selectedItems.includes(item.inventoryNumber)}
                          onChange={() => toggleSelectItem(item)}
                        />
                      )}
                        <div className="flex-shrink-0">
                          <img
                            src={productImageUrl}
                            alt={item.imageAlt}
                            className="w-12 object-contain h-12 rounded-md"
                          />
                        </div>
                        <div className="ml-6 flex-1 flex flex-col justify-between">
                          <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                            <div className="flex justify-between w-full">
                              <h3 className="text-sm w-full max-w-2/3">
                                <Link to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`} className="font-medium text-gray-700 dark:text-white hover:text-gray-800">
                                  {item.description}
                                </Link>
                              </h3>
                              <div class="flex flex-col items-end w-1/3">
                            <p className="ml-4 text-sm font-medium">
                              {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice) + '/st'} 
                            </p>
                            <p className="ml-4 text-sm text-gray-500 dark:text-gray-400 font-medium">
                              {'Radtotal: ' + new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(radTotal)}
                            </p>
                            </div>
                            </div>
                          </div>
                          <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                            <div className="relative inline-block text-left">
                              <div className="flex items-center space-x-2 group">
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-red-400/70 text-white text-xs  transform active:scale-75 transition-transform font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                  onClick={() => removeFromCart(item)}
                                >
                                  -
                                </button>
                                <span className="text-sm font-medium text-gray-900  dark:text-white group-active:animate-pulse-custom">
                                  {item.quantity}
                                </span>
                                <button
                                  type="button"
                                  className="px-4 py-2 bg-gradient-to-r from-green-400  transform active:scale-75 transition-transform to-green-500 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none"
                                  onClick={() => addToCart(item)}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Render "BEST" items separat om samleverans inte är vald */}
              {bestItems.length > 0 && (
                <div className="mt-10">
                  <h2 className="text-lg font-medium text-gray-900 dark:text-white">Beställningsvaror</h2>
                  <p className="text-sm text-gray-600 dark:text-gray-400">(Vi meddelar när beställningsvaror kommer in i lager.)</p>
                  <div className="mt-4 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-600">
                      {bestItems.map((item) => {
                        const productImageUrl = item.productImages && item.productImages.length > 0 ? item.productImages[0] : placeholderImageUrl;
                        const itemPrice = item.discountedPrice !== null && item.discountedPrice !== undefined ? item.discountedPrice : item.defaultPrice;
                        const radTotal = itemPrice * item.quantity;
                        return (
                          <li key={item.inventoryNumber} className="flex relative py-6 px-4 sm:px-6">
                             {selectMode && (
                        <input
                          type="checkbox"
                          className="mr-2 peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800"
                          checked={selectedItems.includes(item.inventoryNumber)}
                          onChange={() => toggleSelectItem(item)}
                        />
                      )}
                            <div className="flex-shrink-0">
                              <img
                                src={productImageUrl}
                                alt={item.imageAlt}
                                className="w-12 object-contain h-12 rounded-md"
                              />
                            </div>
                            <div className="ml-6 flex-1 flex flex-col justify-between">
                              <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 md:flex">
                                <div className="flex justify-between w-full">
                                  <h3 className="text-sm w-full">
                                    <Link to={`/cat/${item.salesCategories[0].slug}/product/${item.inventoryNumber}`} className="font-medium text-gray-700 dark:text-white hover:text-gray-800">
                                      {item.description}
                                    </Link>
                                  </h3>
                                  <p className="ml-4 text-sm font-medium">
                                    {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice)}
                                  </p>
                                </div>
                              </div>
                              <div className="flex-shrink-0 sm:mt-0 sm:ml-6 md:ml-0 md:mt-2">
                                <div className="relative inline-block text-left">
                                  <div className="flex items-center space-x-2 group">
                                    <button
                                      type="button"
                                      className="px-4 py-2 bg-red-400/70 text-white text-xs  transform active:scale-75 transition-transform font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                      onClick={() => removeFromCart(item)}
                                    >
                                      -
                                    </button>
                                    <span className="text-sm font-medium text-gray-900 dark:text-white group-active:animate-pulse-custom">
                                      {item.quantity}
                                    </span>
                                    <button
                                      type="button"
                                      className="px-4 py-2 bg-gradient-to-r from-green-400  transform active:scale-75 transition-transform to-green-500 text-white text-xs font-bold uppercase rounded hover:bg-green-400/65 focus:outline-none"
                                      onClick={() => addToCart(item)}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </>
          )}
</div>
<div className='lg:w-1/3 w-full relative '>
<div className='lg:sticky lg:top-64 bg-white border border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700'>
          

          {/* Visa totals och fraktfritt */}
          <div className="px-5 mt-10  py-10 border-gray-200 bg-white dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
           <h1 className='text-2xl font-bold'>Summering:</h1>{/* Checkbox för samleverans */}
          {(bestItems.length > 0 || isCombinedDelivery) && (
            <div className="mt-10 border-gray-200 dark:border-gray-600 rounded-lg shadow-sm dark:bg-gray-700 dark:text-white">
              <div className="flex items-center my-5">
                <input
                  type="checkbox"
                  className='peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800'
                  checked={isCombinedDelivery}
                  onChange={(e) => setIsCombinedDelivery(e.target.checked)}
                />
                <label htmlFor="combinedDelivery" className="ml-3 block text-sm text-gray-900 dark:text-white">
                  Önskar samleverans?
                </label>
              </div>
            </div>
          )} <div className="mt-4">
          {totalDiscount > 0 && (
            <div className="flex justify-between text-base font-medium text-red-600">
              <span>Total rabatt:</span>
              <span>-{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDiscount)}</span>
            </div>
          )}
        </div>{!isCombinedDelivery ? (
              <>
                <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                  <span>Order Total:</span>
                  <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getNonBestItemsTotal())}</span>
                </div>
                {bestItems.length > 0 && (
                  <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white mt-4">
                    <div><span>Beställningsorder Total:*</span>
                    <div className=' block text-xs text-gray-600 dark:text-gray-400'>*Räknas inte mot fraktfritt.</div></div>
                    
                    <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getBestItemsTotal())}</span>
                    
                  </div>
                )}
              </>
            ) : (
              <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                <span>Total:</span>
                <span>{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(getTotal())}</span>
              </div>
            )}

            <div className="mt-4 ">
            <p className={isFreeShipping ? 'inline-flex items-center rounded-md bg-green-100 px-1.5 py-1 font-bold text-sm px-5 py-3 text-green-700 ' : 'dark:text-white px-1.5 py-0.5 text-xs font-medium'}>
  {isFreeShipping ? (
    <>
      <div className='animate animate-pulse-custom'><FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> {/* Lägg till ikonen */}
      FRAKTFRITT!
      </div>
    </>
  ) : `Kvar till fraktfritt: ${formattedAmountRemaining}`}
</p>
              {!isFreeShipping && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                  <div className="bg-red-600 h-2.5 rounded-full transition-all ease-in-out" style={{ width: `${percentage}%` }}></div>
                </div>
              )}
            </div>
            <div className="m-6 flex gap-10">
                      
                        <Link
                          to="/checkout"
                          className="flex w-full items-center justify-center rounded-md border border-transparent bg-red-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-800"
                        >
                          Till Kassan
                        </Link>
                      </div>
                  
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default CartPage;
