import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = ({ children, handleInputChange, query, isSidebarOpen, showHeader, Header, Sidebar, Cart, toggleSidebar }) => {

  return (
    <>
      {showHeader && (
        <>
          <Header
            handleInputChange={handleInputChange}
            query={query}
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
          />
          <Sidebar handleChange={handleInputChange} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          <Cart />
        </>
      )}
      <div className={`${isSidebarOpen ? 'md:ml-72 pl-4' : 'md:ml-0'} mt-md-20 mt-16 dark:bg-gray-800 transition`}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { handleInputChange, query });
        })}
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
