import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AktivSkola from '../imgs/3dab024d4ca2be0cb9e94f553f23d501.jpg';
import Medvind from '../imgs/medvind-logo.png';
import Rögle from '../imgs/thumb_0.jpg';
import GivingPeople from '../imgs/givingpeople_logo.png';


const SocialtEngagemantCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logos = [
  Medvind,
  Rögle,
    GivingPeople,
    AktivSkola
    // Lägg till fler logotypvägar här
  ];

  return (
    <div className="container mx-auto p-4">
      
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="px-4">
            <img src={logo} alt={`Logo ${index + 1}`} className="mx-auto max-w-96 w-64" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SocialtEngagemantCarousel;
