import React, { useContext, Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FaXmark } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { CartContext } from '../context/cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareQuestion } from '@awesome.me/kit-e012a9aa4e/icons/classic/regular';
export default function Cart() {
  const { cartItems, addToCart, bestItems, nonBestItems, totalDiscount, removeFromCart, getShippingStatus, getNonBestItemsTotal, getBestItemsTotal, getTotal, isCartOpen, toggleCart, setIsCartOpen, campaignDiscount,campaignsDiscount, isCombinedDelivery, setIsCombinedDelivery } = useContext(CartContext);
  const placeholderImageUrl = 'https://via.placeholder.com/350';
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [swipedItem, setSwipedItem] = useState(null); // För att hålla reda på vilken rad som swipats

  const handleSwipe = (itemId) => {
    if (swipedItem === itemId) {
      setSwipedItem(null); // Återställ om samma rad swipas igen
    } else {
      setSwipedItem(itemId); // Sätt den swipade raden
    }
  };
  // Använd korrekt total för fraktfri-beräkning beroende på om samleverans är aktiv
  const cartTotalForShipping = isCombinedDelivery ? getTotal() : getNonBestItemsTotal();
  const { amountRemaining, percentage, isFreeShipping } = getShippingStatus(cartTotalForShipping);

  const formattedAmountRemaining = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(amountRemaining);


 // Funktion för att stänga tooltip när man klickar utanför den
 useEffect(() => {
  const handleClickOutside = (event) => {
    if (!event.target.closest('.tooltip')) {
      setIsTooltipOpen(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);

  // Slå samman alla produkter om samleverans är ikryssad
  const mergedItems = isCombinedDelivery ? [...nonBestItems, ...bestItems] : null;
  
  return (
    <Transition.Root show={isCartOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:z-10" onClose={toggleCart}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full  pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-full  lg:max-w-lg ">
                  <div className="flex pt-8 lg:pt-36 h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 dark:text-white shadow-xl z-40">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white">
                          Din varukorg:
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 dark:text-white hover:text-gray-500"
                            onClick={() => setIsCartOpen(false)}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Close panel</span>
                            <FaXmark className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8 py-6">
                        <div className="flow-root">
                          {/* Rendera antingen alla produkter eller separera beroende på samleverans */}
                          <ul className="-my-6 divide-y divide-gray-200 dark:divide-gray-600">

                            {/* Om samleverans är aktiverad, rendera alla produkter utan rubriker */}
                            {isCombinedDelivery && mergedItems.map((item) => {
                              const imageSrc =
                                item.productThumbnails && item.productThumbnails.length > 0
                                  ? item.productThumbnails[0]
                                  : item.productImages && item.productImages.length > 0
                                  ? item.productImages[0]
                                  : placeholderImageUrl;

                          // Kontrollera om item.discountedPrice är ett giltigt nummer
                          const itemPrice =
                          typeof item.discountedPrice === 'number' && !isNaN(item.discountedPrice)
                            ? item.discountedPrice
                            : item.defaultPrice;
                         const radTotal = itemPrice * item.quantity;
// Kontrollera om produkten är rabatterad
const isDiscounted = typeof item.discountedPrice === 'number' && !isNaN(item.discountedPrice);

// Kontrollera om det är en klippvara baserat på expirationDate
const isKlipp = item.expirationDate !== null && item.expirationDate !== undefined ? true : false;
const isKampanj = item.discountType && !isKlipp;
                                  const priceClass = isKlipp || isDiscounted ? 'text-red-600 underline' : '';
                              return (
                                <li className="flex py-6" key={item.inventoryNumber}>
                                
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                    <img
                                      src={imageSrc}
                                      alt={item.description}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>
                                 
                                  <div className="ml-4 flex flex-1 flex-col">
                                  {isKlipp && (
      <div className='klippvara text-xs font-semibold text-red-700'>KLIPPVARA</div>
    )}   {isKampanj && (
      <div className='klippvara text-xs font-semibold text-orange-700'>KAMPANJ</div>
    )}
                                    <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                            <h3>
                                            <Link to={`/cat/${item.salesCategories[0]?.slug}/product/${item.inventoryNumber}`}>{item.description}</Link>
                                            </h3>
                                             
                                            <p className={`ml-4 flex-col ${priceClass}`}>
      {new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
      }).format(itemPrice)}
       <span className='inline-block dark:text-white text-xs'> Radtotal: {new Intl.NumberFormat('sv-SE', {
                                                style: 'currency',
                                                currency: 'SEK',
                                              }).format(radTotal)}
                                              </span>
    </p>
                                          </div>
                                        </div>
                                    <div className="flex gap-4 group">
                                      <button
                                        className="px-4 py-2 bg-gradient-to-r from-red-400 to-red-500 transform active:scale-75 transition-transform text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                        onClick={() => {
                                          removeFromCart(item);
                                        }}
                                      >
                                        -
                                      </button>
                                      <p className='group-active:animate-pulse-custom'>{item.quantity}</p>
                                      <button
                                        className="px-4 py-2 bg-gradient-to-r from-green-400 to-green-500 transform active:scale-75 transition-transform text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                        onClick={() => {
                                          addToCart(item);
                                        }}
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}

                            {/* Om inte samleverans är aktiverad, rendera separata listor med rubriker */}
                            {!isCombinedDelivery && (
                              <>
                                {/* Rubrik för vanliga produkter */}
                              {/* Rubrik för beställningsvaror */}
{bestItems.length > 0 && (
  <h2 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
                                  Order:
                                </h2>
)}  
                                {nonBestItems.map((item) => {
                                  const imageSrc =
                                    item.productThumbnails && item.productThumbnails.length > 0
                                      ? item.productThumbnails[0]
                                      : item.productImages && item.productImages.length > 0
                                      ? item.productImages[0]
                                      : placeholderImageUrl;

                                      const itemPrice =
  typeof item.discountedPrice === "number" && !isNaN(item.discountedPrice)
    ? item.discountedPrice
    : item.defaultPrice;
                                     
// Kontrollera om produkten är rabatterad
const isDiscounted = typeof item.discountedPrice === 'number' && !isNaN(item.discountedPrice);
const isKlipp = item.expirationDate !== null && item.expirationDate !== undefined ? true : false;
const isKampanj = item.discountType && !isKlipp;

const priceClass = isKlipp || isDiscounted ? 'text-red-600 underline' : '';
const totalItemPrice = itemPrice * item.quantity;
const totalDiscount = item.discount || 0; // Separera rabatt från totalen
const finalPrice = totalItemPrice - totalDiscount;


                                  return (
                                    <li className="flex py-6" key={item.inventoryNumber}>
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                        <img
                                          src={imageSrc}
                                          alt={item.description}
                                          className="h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className="ml-4 flex flex-1 flex-col">
                                      {isKlipp && (
      <div className='klippvara text-xs font-semibold text-red-700'>KLIPPVARA</div>
    )}  {isKampanj && (
      <div className='klippvara text-xs font-semibold text-orange-700'>KAMPANJVARA</div>
    )}
                                        <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                            <h3>
                                            <Link to={`/cat/${item.salesCategories[0]?.slug}/product/${item.inventoryNumber}`}>{item.description}</Link>
                                            </h3>
                                             
                                            <p className={`ml-4 flex-col items-end text-right ${priceClass}`}>
      {new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
      }).format(itemPrice)}
       <span className='inline-block dark:text-white text-xs'> Radtotal: {new Intl.NumberFormat('sv-SE', {
                                                style: 'currency',
                                                currency: 'SEK',
                                              }).format(finalPrice)}
                                              </span>
    </p>
                                          </div>
                                        </div>
                                        <div className="flex gap-4 group">
                                          <button
                                            className="px-4 py-2 bg-gradient-to-r from-red-400 to-red-500 transform active:scale-75 transition-transform text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              removeFromCart(item);
                                            }}
                                          >
                                            -
                                          </button>
                                          <p className='group-active:animate-pulse-custom'>{item.quantity}</p>
                                          <button
                                            className="px-4 py-2 bg-gradient-to-r from-green-400 to-green-500 transform active:scale-75 transition-transform text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              addToCart(item);
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
{/* Rubrik för beställningsvaror */}
{bestItems.length > 0 && (
  <h2 className="text-lg font-medium text-gray-900 dark:text-white mt-6">
    Beställningsvaror:
  </h2>
)}
                                {bestItems.map((item) => {
                                  const imageSrc =
                                    item.productThumbnails && item.productThumbnails.length > 0
                                      ? item.productThumbnails[0]
                                      : item.productImages && item.productImages.length > 0
                                      ? item.productImages[0]
                                      : placeholderImageUrl;

                                      const itemPrice =
                                      typeof item.discountedPrice === 'number' && !isNaN(item.discountedPrice)
                                        ? item.discountedPrice
                                        : item.defaultPrice;
                                     const radTotal = itemPrice * item.quantity;

// Kontrollera om produkten är rabatterad
const isDiscounted = typeof item.discountedPrice === 'number' && !isNaN(item.discountedPrice);
const isKlipp = item.expirationDate !== null && item.expirationDate !== undefined ? true : false;
const isKampanj = item.discountType && !isKlipp;
const priceClass = isKlipp || isDiscounted ? 'text-red-600 underline' : '';


                                  return (
                                    <li className="flex py-6" key={item.inventoryNumber}>
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 dark:border-gray-600">
                                        <img
                                          src={imageSrc}
                                          alt={item.description}
                                          className="h-full w-full object-cover object-center"
                                        />
                                      </div>
                                      <div className="ml-4 flex flex-1 flex-col">
                                      {isKlipp && (
      <div className='klippvara text-xs font-semibold text-red-700'>KLIPPVARA</div>
    )}  {isKampanj && (
      <div className='klippvara text-xs font-semibold text-orange-700'>KAMPANJVARA</div>
    )}
                                           <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                            <h3>
                                            <Link to={`/cat/${item.salesCategories[0]?.slug}/product/${item.inventoryNumber}`}>{item.description}</Link>
                                            </h3>
                                             
                                            <p className={`ml-4 flex-col items-end text-right ${priceClass}`}>
      {new Intl.NumberFormat('sv-SE', {
        style: 'currency',
        currency: 'SEK',
      }).format(itemPrice)}
       <span className='inline-block dark:text-white text-xs'> Radtotal: {new Intl.NumberFormat('sv-SE', {
                                                style: 'currency',
                                                currency: 'SEK',
                                              }).format(radTotal)}
                                              </span>
    </p>
                                          </div>
                                        </div>
                                        <div className="flex gap-4 group">
                                          <button
                                            className="px-4 py-2 bg-gradient-to-r from-red-400 to-red-500 transform active:scale-75 transition-transform text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              removeFromCart(item);
                                            }}
                                          >
                                            -
                                          </button>
                                          <p className='group-active:animate-pulse-custom'>{item.quantity}</p>
                                          <button
                                            className="px-4 py-2 bg-gradient-to-r from-green-400 to-green-500 transform active:scale-75 transition-transform text-white  text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                                            onClick={() => {
                                              addToCart(item);
                                            }}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                     
                      <div className="border-t border-gray-200 dark:border-gray-600 px-4 py-6 sm:px-6">
                      {bestItems.length > 0 && (
                       <>
  <div className="flex justify-between w-full mt-6 my-6">
    <div className='flex items-center'><input
      id="combinedDelivery"
      type="checkbox"
      checked={isCombinedDelivery}
      onChange={(e) => setIsCombinedDelivery(e.target.checked)}
      className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
    />
    <label htmlFor="combinedDelivery" className="ml-3 block text text-gray-900 dark:text-white">
      Önskar samleverans?
    </label>
    </div>
    <div className='tooltip flex'>
 {/* Tooltip Icon */}
 <FontAwesomeIcon 
   icon={faSquareQuestion} 
   size={'lg'}
   className="text-gray-700 dark:text-gray-300 cursor-pointer" 
   onClick={() => setIsTooltipOpen(!isTooltipOpen)} // Toggle tooltipen vid klick
 />
</div>
  </div>
  


{isTooltipOpen && (
 <div className='tooltip-info w-full  bg-gray-200 text-gray-900 text-sm p-2 mt-2 mb-5 rounded-md shadow-lg'>
   Vid samleverans levereras alla dina varor samtidigt, men leveransen sker inte förrän alla dina varor finns i lager.
 </div>
)}
    </>
 

 
)}
<div className="mt-4">
{totalDiscount > 0 && (
    <div className="flex justify-between text-base font-medium text-red-600">
      <span>Total rabatt:</span>
      <span>
        -{new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(totalDiscount)}
      </span>
    </div>

  )}
  
{campaignsDiscount > 0 && (
    <div className="inline-flex items-center rounded-md bg-orange-100 px-2 py-1 my-2 text-xs font-medium text-orange-700 ">
      <span className='mr-1 '>- varav kampanjrabatt: </span>
      <span>
          { new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(campaignsDiscount)}
      </span>
    </div>
  )}
    </div>

{!isCombinedDelivery ? (
                          <div>
                            {/* Visa separat total för order och beställningsorder */}
                            <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                              <span>Order Total</span>
                              <span>
                                {new Intl.NumberFormat('sv-SE', {
                                  style: 'currency',
                                  currency: 'SEK',
                                }).format(getNonBestItemsTotal())}
                              </span>
                            </div>
                            {bestItems.length > 0 && (
                              <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white mt-4">
                                <span>Beställningsorder Total</span>
                                <span>
                                  {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                  }).format(getBestItemsTotal())}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                            {/* Visa sammanslagen total */}
                            <span>Total</span>
                            <span>
                              {new Intl.NumberFormat('sv-SE', {
                                style: 'currency',
                                currency: 'SEK',
                              }).format(getTotal())}
                            </span>
                          </div>
                        )}


                        <div className="mt-4">
                          <p
                            className={
                              isFreeShipping
                                ? 'inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700'
                                : ''
                            }
                          >
                            {isFreeShipping
                              ? 'FRAKTFRITT!'
                              : `Kvar till fraktfritt: ${formattedAmountRemaining}`}
                          </p>

                          {!isFreeShipping && (
                            <div className="w-full bg-gray-200 rounded-full h-2.5">
                              <div
                                className="bg-red-600 h-2.5 rounded-full transition-all ease-in-out"
                                style={{ width: `${percentage}%` }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="m-6 flex gap-10">
                      <Link
                          to="/varukorg"
                          onClick={() => setIsCartOpen(false)}
                          className="flex items-center justify-center rounded-md border border-red-400  px-6 py-3 text-base font-medium text-gray-800 shadow-sm hover:bg-red-400 dark:text-red-100 hover:text-white"
                        >
                          Visa varukorg
                        </Link>
                        <Link
                          to="/checkout"
                          onClick={() => setIsCartOpen(false)}
                          className="flex items-center justify-center rounded-md border border-transparent bg-red-700 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-red-800"
                        >
                          Till Kassan
                        </Link>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500 pb-12">
                        <p>
                          eller{' '}
                          <button
                            type="button"
                            className="font-bold text-indigo-600 dark:text-gray-300 hover:text-indigo-500"
                            onClick={() => setIsCartOpen(false)}
                          >
                            fortsätt handla
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
