import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// Funktion för att kryptera data och lägga till en flagga som indikerar att den är krypterad
export const encryptData = (data) => {
    if (!SECRET_KEY) {
        throw new Error('Secret key is missing');
    }
    const dataToEncrypt = { ...data, isEncrypted: true };  // Lägg till isEncrypted-flagga
    return CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), SECRET_KEY).toString();
};

// Funktion för att dekryptera data endast om den är krypterad
export const decryptData = (cipherText) => {
    if (!SECRET_KEY) {
        throw new Error('Secret key is missing');
    }

    try {
        const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        // Om datan har flaggan isEncrypted, ta bort flaggan och returnera datan
        if (decryptedData.isEncrypted) {
            delete decryptedData.isEncrypted;  // Ta bort flaggan efter dekryptering
            return decryptedData;
        } else {
            // Om datan inte har flaggan, är den redan dekrypterad
            return decryptedData;
        }
    } catch (error) {
        console.error('Fel vid dekryptering:', error);
        throw new Error('Dekryptering misslyckades');
    }
};