import React from 'react';

const Integritetspolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8 mt-24 pt-24">
            <h1 className="text-4xl font-bold mb-6">Integritetspolicy</h1>
            <p className="text-gray-700 mb-2">Uppdaterad 2021-03-02</p>
            
            <p className="mb-4">Vi på GastronomiLeverantören värnar om våra kunders integritet...</p>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Vad är en personuppgift?</h2>
                <p>En personuppgift är en uppgift som på något sätt kan kopplas till dig som individ. Detta innefattar adress, personnummer, namn, telefonnummer, mailadress. Detta är vad man kallar för vanliga personuppgifter – sedan finns det också uppgifter som kallas för känsliga uppgifter. Dessa innefattar bland annat sjukdom, kön, etnicitet, familjesituation med mera.</p>
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">GDPR/DSF – Vad innebär den nya lagen?</h2>
                <p>Dataskyddsförordningen eller, GDPR som den förkortas till på engelska är en lagstiftning som nu skall börja gälla för företag som handlar eller utför arbete inom EU. Enligt denna nya lag läggs det stor vikt vid individens rättigheter inom hantering av personuppgifter, vars lagar tidigare gått under Personuppgiftslagen (PUL) inom det svenska rättsväsendet. Den nya lagen innebär en del nytt som alla företag som hanterar personuppgifter på något sätt måste förhålla sig till. Likaså vi! Kortfattat handlar det om följande:
</p>
                <ul className="list-disc list-inside mt-6 pl-6">
                    <li>Vilka uppgifter har vi om er som kund</li>
                    <li>Varför behöver vi dessa uppgifter</li>
                    <li>Var sparar vi er information – och vilka säkerhetsåtgärder vidtar vi för att skydda er integritet</li>
                    <li>Hur länge sparar vi er information</li>
                    <li>Vilka förutom oss på GastronomiLeverantören skulle kunna ha tillgång till den information vi har om er.</li>
                </ul>
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Individens rättigheter</h2>
                
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Rätt till tillgång</h3>
                    <p>Detta innebär att du har rätt att begära att få ett registerutdrag på alla uppgifter vi har om dig. Vi vill vara så öppna mot er som möjligt, men ibland vill man kanske få lite fördjupad insikt i hur information hanteras och det förstår vi självklart. Enligt den nya lagen så har ni rätt att få ut all information vi har om er. Detta innebär att vi ger er ett utdrag där vi visar på vart er information används, vilken information vi använt, hur vi har fått tillgång till informationen, hur länge det sparas, ja – allt som vi visar på längre ned i denna integritetspolicy</p>
                </div>
                
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Rätt att bli glömd</h3>
                    <p>Ni har rätt att begära att få de personuppgifter vi hanterar om er raderade om något av följande krav uppfylls:Uppgifterna vi har inte längre är nödvändiga – exempelvis om ni inte längre handlar av oss och önskar få era uppgifter raderade.


Om ni invänder mot intresseavvägning vilket i vissa fall är vår lagliga grund till att använda en del av era personuppgifter. Om ditt skäl för att invända väger tyngre än intresseavvägning kan ni därför få era uppgifter raderade. Om vi på något sätt skulle hantera era uppgifter på ett olagligt eller missvisande sätt – dvs om vi använder era uppgifter i brottsligt syfte. Önskar ni få era uppgifter raderade vill vi påpeka att det kan finnas vissa rättsliga skyldigheter som förhindrar oss från att kunna radera era uppgifter – det kan vara andra lagstiftningar som väger tyngre i det enskilda fallet. Exempelvis kan bokförings – och skattelagstiftningar komma att väga tyngre. Skulle det vara så att vi på något sätt, om det beror på legal skyldighet eller om vi av annan rättslig orsak inte kan radera så ser vi till att inaktivera era uppgifter för att göra dem otillgängliga för användning i företagets bruk.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Rätt till rättelse
                    </h3>
                    <p>Ni har alltid rätt att begära att vi ändrar/kompletterar eventuella felaktiga uppgifter. Likaså om ni vill komplettera ofullständiga uppgifter.

</p>
                </div>

                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Rätt till begränsning
                    </h3>
                    <p>Om ni anser att det inte finns någon anledning för oss att ha tillgång till visa uppgifter har ni rätt att begära att vi raderar dessa. Kom ihåg att det kan finnas en legal skyldighet från vår sida, precis som med rätten att bli glömd/raderad. Likaså måste vi göra en kontroll för att se att vi inte har något arbetsväsentligt ändamål till att behålla den uppgift ni önskar begränsad. Väger er ansökan om att få en personuppgift tyngre än den intresseavvägning vi har med er så raderar vi uppgiften.</p> </div>
                    <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Berättigat intresse
                    </h3>
                    <p>I de fall vi använder en intresseavvägning som laglig grund för ett ändamål har du möjlighet att invända mot behandlingen. För att kunna fortsätta behandla dina personuppgifter efter en sådan invändning behöver vi kunna visa ett tvingande berättigat skäl för den aktuella behandlingen som väger tyngre än dina intressen, rättigheter eller friheter. I annat fall får vi bara behandla uppgifterna för att fastställa, utöva eller försvara rättsliga anspråk.</p></div>
                    <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Direkt marknadsföring
                    </h3>
                    <p>Ni har rätt att invända mot hur vi hanterar era uppgifter i marknadsföringssyfte. Om ni invänder mot hur vi använder era personuppgifter i marknadsföring kommer vi att ta bort dessa uppgifter. Exempelvis om ni önskar slippa få nyhetsbrev med kampanjer eller all/viss information tar vi bort er från våra maillistor.</p></div>
                {/* Fler rättigheter läggs till i liknande strukturer */}
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Personuppgiftsbiträden</h2>
                <p>Ett personuppgiftsbiträde är ett företag och/eller person som får temporärt ansvar för era personuppgifter för att utföra ett uppdrag där dessa uppgifter uppfyller ett syfte hos personuppgiftsbiträdet. Exempelvis så behöver DHL som vi främst fraktar varor med tillgång till er leveransadress för att leverera ut era varor. För att vi skall kunna utföra vårt arbete på bästa sätt krävs det att vi har vissa personuppgiftsbiträden inblandade i våra olika processer från lagt beställning till levererad vara. Vi har olika personuppgiftsbiträden för följande olika processer:</p>
                <ul className="list-disc list-inside pl-6">
                    <li>Frakt/Transport</li>
                    <li>IT-Lösningar</li>
                    <li>Marknadsföring</li>
                </ul>
                <p className='my-6'>Under frakt/transport innefattas de företag vi samarbetar med för att leverera/transportera era varor till er. De behöver tillgång till er leveransadress, samt eventuellt telefonnummer för att kunna leverera era varor till er. Telefonnummer kan komma att användas för att skicka ut aviseringar vid leverans. Våra personuppgiftsbiträden för frakt och transportärenden är DHL och Bring.


Under IT-lösningar finner vi de företag vi arbetar för bokföring och andra IT-relaterade lösningar. Vi använder oss av Visma SPCS för bokföring och administration. Vi använder oss av en serverlösning som tillhandahålls oss från IXX Konsult, som också sköter kontinuerliga backuper av vår server åt oss. Varken Visma eller IXX har direkt koppling till era uppgifter, men vid IT-relaterade problem eller problem i programvaran som dessa företag levererar kan det förekomma att dessa företag kan se era uppgifter. Skulle detta inträffa har dessa personuppgiftsbiträden ingen rätt att föra vidare eller distribuera någon information de fått tillgång till från oss.


Våra hemsidor drivs in-house och därför är det bara vissa anställda med inloggningsuppgifter på GastronomiLeverantören AB som har tillgång till era uppgifter. Under marknadsföring finner vi Editnews som är leverantör av vårt epostprogram. Editnews har tillgång till de uppgifter vi tillhandahåller för att skicka ut relevant information till er. Som ett steg i att förbättra vår hantering av era uppgifter sparar vi endast era mailadresser i detta program för att minimera risken för eventuella säkerhetsbrister. Ni som prenumeranter på våra nyhetsbrev & utskick kan när som helst avregistrera er direkt från utskicken (ni finner länk längst ner i mailet).</p>
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Vilka uppgifter vi hanterar</h2>
                <p>i använder främst följande personuppgifter för ett huvudsakligt ändamål: för att ni skall kunna köpa varor av oss och att vi skall kunna leverera dessa till rätt företag. Vi har en process som innebär att när vi lägger upp er som kund hos oss så sparas era uppgifter endast i vårt administrationsprogram. Därefter gör vi kopplingen till er genom ert kundnummer som ni får av oss vid registreringstillfället. Detta gör vi som ett steg i att förbättra, sortera och förminska antalet ställen där era uppgifter lagras. Enbart anställda på GastronomiLeverantören kan veta vilka uppgifter som är lagrade på ett kundnummer, då vi är de enda med tillgång till administrationsprogrammet.

Dessa uppgifter behöver vi för att kunna lägga upp er som kund i vårt system, dvs för att ni ska kunna handla av oss:</p>
                <ul className="list-disc list-inside pl-6">
                    <li>Företagsnamn</li>
                    <li>Organisationsnummer/Kreditbetyg</li>
                    <li>Leverans och Fakturaadress</li>
                    <li>Telefonnummer</li>
                    <li>Kontaktperson</li>
                    <li>Mailadress</li>
                </ul>

    <h2 className="text-2xl font-semibold my-4">Personuppgifter vi hanterar</h2>
    <p className="mb-4">Vi samlar in följande personuppgifter för att säkerställa att vi kan tillhandahålla våra tjänster på ett effektivt och säkert sätt:</p>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Företagsnamn</h3>
            <p className="text-gray-700">För att säkerställa att det är rätt köpare som handlar av oss.</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Organisationsnummer / Kreditbetyg</h3>
            <p className="text-gray-700">För att bekräfta företagets status och kreditvärdighet. Vid kreditbetyg C eller lägre krävs förskottsbetalning.</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Leverans- och Fakturaadress</h3>
            <p className="text-gray-700">För att säkerställa att leverans och faktura når rätt adress (inklusive postnummer och ort).</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Telefonnummer</h3>
            <p className="text-gray-700">Används för att kontakta kunden vid eventuella avvikelser.</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Kontaktperson</h3>
            <p className="text-gray-700">För att kunna kontakta rätt person inom företaget.</p>
        </div>

        <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-medium">Mailadress</h3>
            <p className="text-gray-700">För att skicka ut orderinformation, fakturor, eller vid behov att kontakta kunden. Används även för marknadsföring och nyhetsbrev, från vilket man kan avregistrera sig via länk i varje utskick.</p>
        </div>
    </div>


            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Laglig grund för användning av personuppgifter</h2>
                <p>Intresseavvägning: ”Personuppgifter får behandlas bara om den registrerade har lämnat sitt samtycke till behandlingen eller om behandlingen är nödvändig för att… en arbetsuppgift av allmänt intresse skall kunna utföras” – 10 § Personuppgiftslag (1998:204)</p>
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Lagring av personuppgifter</h2>
                <p>Personuppgifter lagras i upp till 24 månader efter det att sista beställning lagts. De uppgifter som angår orderhistorik & betalningshistorik lagras i 7 år enligt Bokföringslagens bestämmelser. All övrig information relaterat till kunden raderas från våra administrationsprogram och marknadsföringsprogram efter sagda 24 månader inaktivitet. Önskar kund få sin information raderad tidigare än ovannämnda 24 månader hänvisar vi er till vår informationsmail: info@gastronomileverantoren.se.</p>
            </section>
            
            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Kontaktinformation</h2>
                <p>Vi på GastronomiLeverantören tackar för att ni tagit er tid att läsa denna integritetspolicy. Har ni några synpunkter på någon information som angetts i denna Integritetspolicy så är ni välkomna att kontakta oss på följande vis:</p>
                <ul className="list-none">
                    <li>Telefon: <a href="tel:0431449130" className="text-blue-600 hover:underline">0431 44 91 30</a> (Mån-Fre: 08.30-16.30)</li>
                    <li>Email: <a href="mailto:info@gastronomileverantoren.se" className="text-blue-600 hover:underline">info@gastronomileverantoren.se</a></li>
                </ul>
            </section>
        </div>
    );
};

export default Integritetspolicy;
