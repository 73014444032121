import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const OrderSuccess = () => {
  const location = useLocation();
  const { userNumber, bestItemsOrder, nonBestItemsOrder, combinedOrder } = location.state || {}; // Hämta orderdokumenten


console.log('alla state värden:', 'usernumber:', userNumber, 'bestItemsOrder:', bestItemsOrder, 'nonBestItemsOrder:', nonBestItemsOrder, 'combinedOrder:', combinedOrder);

  // Funktion för att beräkna subtotal, rabatt och total för en specifik order
  const calculateTotals = (orderData) => {
    let subtotal = 0;
    let totalDiscount = 0;

    orderData.lines.forEach((line) => {
      const quantity = line.quantity.value;
      const price = line.price.value;
      const formattedDiscountedPrice = line.unitPrice ? line.unitPrice.value : price;

      subtotal += price * quantity;
      totalDiscount += (price - formattedDiscountedPrice) * quantity;
    });

    const shippingCost = 0; // Ändra om du har dynamiska fraktkostnader
    const orderTotal = subtotal - totalDiscount + shippingCost;
console.log('orderTotal:', orderTotal);
    return { subtotal, totalDiscount, shippingCost, orderTotal };
  };


  // Rendera ordern baserat på orderData
  const renderOrder = (orderData, orderTitle) => {
    const { subtotal, totalDiscount, shippingCost, orderTotal } = calculateTotals(orderData);

    return (
      <div className="mt-10 border-t border-gray-200 text-center mx-auto">
        
        <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
          { /* orderData.lines.map((line, index) => (
            <div key={index} className="flex space-x-6 border-b border-gray-200 py-5">
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">
                    <a>{line.itemDescription.value}</a>
                  </h4>
                </div>
                <div className="mt-6 flex flex-1 items-end">
                  <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900">Antal:</dt>
                      <dd className="ml-2 text-gray-700">{line.quantity.value}</dd>
                    </div>
                    
                  </dl>
                </div>
              </div>
            </div>
          )) */}
          <div className="sm:pl-6">
            <dl className="space-y-6 border-t border-gray-200 py-5 px-5 text-sm">
             
          
            
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Ordertotal:</dt>
                <dd className="text-gray-900">
                  {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(orderTotal)}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  }; 

  return (
    <div className="bg-white">
      <div className="mx-auto text-center max-w-3xl text-center md:text-left px-4 py-16 mt-48 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl mx-auto">
          <h1 className="text-base text-center font-medium text-indigo-600">Tack för din order!</h1>
          <p className="mt-2 text-4xl font-bold  text-center  tracking-tight sm:text-5xl">Den är på väg!</p>
          <p className="mt-2 text-base  text-center text-gray-500">
            Din order behandlas av oss och du får inom kort ett ordererkännande skickat till dig.
            Du kan även kontrollera status på din order under <span className='text-green-600 font-semibold transform hover:scale-75 transition-transform'><Link to={`/user/${userNumber}/order-history`}>Orderhistorik".</Link></span>
          </p>
        </div>

       {/* Rendera kombinerad order om samleverans är vald */}
       { /* combinedOrder && renderOrder(combinedOrder, 'Samlad Beställning')}

        {/* Rendera beställningen för ordinarie varor om samleverans inte är vald */}
        { /*  nonBestItemsOrder && renderOrder(nonBestItemsOrder, 'Ordinarie Beställning')}

        {/* Rendera beställningen för beställningsvaror om samleverans inte är vald */}
        { /*  bestItemsOrder && renderOrder(bestItemsOrder, 'Beställningsvaror')  */}
      </div>
    </div>
  );
};

export default OrderSuccess;
