import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Products from '../Products/Products';
import { useProductContext } from '../context/ProductContext';
import { useUserContext } from '../context/userContext';
import SupplierEditForm from './SupplierEditForm'; // Importera redigeringsformuläret
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase';
import SinglePromotion from '../components/SinglePromotion';
import DiscountNotification from '../components/DiscountNotification';
const SupplierPage = () => {
    const { selectedSupplier } = useParams();  // Få `selectedSupplier` från URL-parametrarna
    const { filterProducts, supplierData } = useProductContext();
    const { role, supplierId } = useUserContext();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [sortOrder, setSortOrder] = useState('default');
    const [bannerImage, setBannerImage] = useState('');
    const [logoImage, setLogoImage] = useState('');  // Nytt state för logotyp
    const [description, setDescription] = useState('');
    const [showInStockOnly, setShowInStockOnly] = useState(false);

    // Kontrollera att `selectedSupplier` och `supplierData` finns innan vi försöker hitta leverantörsinformationen
    const supplierInfo = supplierData?.find(supplier => supplier.supplierName === selectedSupplier);


   useEffect(() => {
    const fetchSupplierInfo = async () => {
        // Kolla att supplierInfo och supplierInfo.supplierId finns
        if (supplierInfo?.supplierId) {
            try {
                const supplierDocRef = doc(db, 'suppliers', supplierInfo.supplierId);
                const supplierDoc = await getDoc(supplierDocRef);
                if (supplierDoc.exists()) {
                    const data = supplierDoc.data();
                    setBannerImage(data.bannerImage || '');
                    setLogoImage(data.logoImage || '');
                    setDescription(data.description || '');
                } else {
                    setBannerImage('');
                    setLogoImage('');
                    setDescription('');
                }
            } catch (error) {
                console.error('Error fetching supplier info:', error);
            }
        }
    };

    // Kör endast fetch om selectedSupplier och supplierInfo finns
    if (selectedSupplier && supplierInfo) {
        fetchSupplierInfo();
    }
}, [selectedSupplier, supplierInfo]);

useEffect(() => {
    // Återställ kategori-filtret när leverantören byts
    setSelectedCategory('');
}, [selectedSupplier]);

console.log(selectedSupplier);

    useEffect(() => {
        let filtered = filterProducts(selectedCategory, selectedSupplier);
        if (showInStockOnly) {
            filtered = filtered.filter(product => product.warehouseDetails.some(detail => detail.available > 0));
        }
        setFilteredProducts(filtered);
    }, [selectedCategory, selectedSupplier, filterProducts, showInStockOnly]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    
    // Lägg till en kontroll som hindrar komponenten från att renderas om `selectedSupplier` inte är definierad
    if (!selectedSupplier || !supplierInfo) {
        return <div>Laddar in information...</div>;  // Eller någon annan visuell feedback för laddning
    }



    const sortProducts = (products, order) => {
        switch (order) {
            case 'nameAZ':
                return products.slice().sort((a, b) => (a.description || '').localeCompare(b.description || ''));
            case 'nameZA':
                return products.slice().sort((a, b) => (b.description || '').localeCompare(a.description || ''));
            case 'supplierAZ':
                return products.slice().sort((a, b) => (a.supplierDetails[0]?.supplierName || '').localeCompare(b.supplierDetails[0]?.supplierName || ''));
            case 'supplierZA':
                return products.slice().sort((a, b) => (b.supplierDetails[0]?.supplierName || '').localeCompare(a.supplierDetails[0]?.supplierName || ''));
            default:
                return products;
        }
    };

    const sortedProducts = sortProducts(filteredProducts, sortOrder);

    const uniqueCategories = [...new Set(filteredProducts.flatMap(product => product.salesCategories.map(category => category.slug)))];

    const filteredCategories = uniqueCategories.map(slug => {
        return filteredProducts.flatMap(product => product.salesCategories).find(category => category.slug === slug);
    });

    const canEdit = role === 'admin' || (role === 'supplier' && String(supplierId) === supplierInfo?.supplierId);

    return (
        <div className='mt-24 pt-24'>
            <DiscountNotification />
            {canEdit && <SupplierEditForm supplierName={selectedSupplier} supplierId={supplierInfo.supplierId} />} {/* Visa redigeringsformulär om användaren har behörighet */}
            <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center dark:text-gray-400 space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            {/* Breadcrumb kod */}
                        </Link>
                    </li>
                    <li aria-current="page">
                        <div className="flex items-center">
                            <Link to={`/supplier/${selectedSupplier}`}>{selectedSupplier}</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <div className="flex items-center p-5">
                {logoImage && (
                    <img
                        src={logoImage}
                        alt={`${selectedSupplier} Logo`}
                        className="h-12 w-12 object-contain mr-3"  // Stil för logotypen
                    />
                )}
                <h1 className="text-4xl font-extrabold dark:text-white">{selectedSupplier || "All Products"}</h1>
            </div>
            <div className="flex flex-col items-center p-5 ">
                {bannerImage && <img src={bannerImage} alt={`${selectedSupplier} Banner`} className="mb-4 w-full h-64 object-cover" />}
                {description && <p className="text-center dark:text-gray-300 md:text-left md:text-md my-4 mr-4 p-5">{description}</p>}
            </div>
            <SinglePromotion supplierSlug={selectedSupplier} />
            <h2 className='dark:text-white mx-auto font-bold px-7'>{filteredProducts.length} varor</h2>
            <div className='flex justify-start flex-col md:flex-row md:mx-0 mx-3 gap-10 mt-3  px-7'>
            {/* Dropdown för att välja kategori */}
            <div className='flex justify-center flex-col dark:text-gray-200'><span>Filtrera:</span>
            <select className='dark:bg-transparent dark:text-white dark:border-gray-600' value={selectedCategory} onChange={handleCategoryChange}>
                <option value="">Alla kategorier</option>
                {filteredCategories.map((category, index) => (
                    <option className='dark:bg-transparent dark:text-gray-800 ' key={index} value={category.slug}>{category.description}</option>
                ))}
            </select>
            </div>
            {/* Dropdown för att välja sortering */}
            <div className='flex flex-col dark:text-gray-200'><span>Sortera efter:</span>
            <select className='dark:bg-transparent  dark:border-gray-600' value={sortOrder} onChange={handleSortChange}>
                <option className='dark:bg-transparent dark:text-gray-800 ' value="default ">Standard</option>
                <option className='dark:bg-transparent dark:text-gray-800 'value="nameAZ">Produktnamn: Fallande (A-Ö)</option>
                <option className='dark:bg-transparent dark:text-gray-800 'value="nameZA">Produktnamn: Stigande (Ö-A)</option>
                <option className='dark:bg-transparent dark:text-gray-800 'value="supplierAZ">Leverantör: Fallande (A-Ö)</option>
                <option className='dark:bg-transparent dark:text-gray-800 'value="supplierZA">Leverantör: Stigande (Ö-A)</option>
            </select></div>
            <div className='m-3 dark:bg-transparent dark:text-white dark:border-gray-600 border-slate-300'>
                <label >
                    <input
                        type="checkbox"
                        checked={showInStockOnly}
                        onChange={(e) => setShowInStockOnly(e.target.checked)}
                   className='mr-2' />
                    Visa endast produkter i lager
                </label>
            </div>
            </div>
            {/* Lista produkter baserat på vald kategori och/eller leverantör */}
            <Products products={sortedProducts} />
          
        </div>
    );
};

export default SupplierPage;
