// Products.js
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/Card';

const Products = ({ products, selectedCategory }) => {


  return (
    <section className="card-container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 xl:grid-cols-4 mt-10">
      {[...products].map((product) => (
        <Card key={product.inventoryNumber} product={{ ...product }} selectedCategory={selectedCategory} />
      ))}
    </section>
  );
};

export default Products;
