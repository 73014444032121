import React, { useState } from "react";
import { useParams } from "react-router-dom";
import RoomCanvas from "./RoomCanvas";
import { useProductContext } from "../context/ProductContext";

const ProductViewer = () => {
  const environments = [
    {
      name: "Köksbänk",
      thumbnail: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/koksbank.jpg",
      background: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/koksbank.jpg",
      customScaleFactor: 1.1,
      reference: "Mått på referens 50cm",
      initialPosition: { x: 60, y: 74 }, // Startposition för produkten
    },
    {
      name: "Kassa",
      thumbnail: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/kassa-butik.jpg",
      background: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/kassa-butik.jpg",
      initialPosition: { x: 30, y: 80 },
      customScaleFactor: 1.2,
    },
    
    {
      name: "Presentkorg",
      thumbnail: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/presentkorg-new.jpg",
      background: "https://storage.googleapis.com/gastrodatabas.appspot.com/market/presentkorg-new.jpg",
      initialPosition: { x: 30, y: 78 },
      customScaleFactor: 1.6,
     
    },
  ];
  

  const { products } = useProductContext();
  const { inventoryNumber } = useParams();

  const [selectedEnvironment, setSelectedEnvironment] = useState(environments[0]); // Defaultmiljö
  const product = products.find((p) => p.inventoryNumber === inventoryNumber);

  if (!product) {
    return <p className="text-center text-red-500 text-xl">Produkten kunde inte hittas</p>;
  }

  const widthAttr = product.attributes.find((attr) => attr.id === "BREDD")?.value || "0";
  const heightAttr = product.attributes.find((attr) => attr.id === "HOJD")?.value || "0";

  const productWidth = parseFloat(widthAttr.replace(",", "."));
  const productHeight = parseFloat(heightAttr.replace(",", "."));

  return (
    <div className="container mx-auto p-6 pt-32">
        <div className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700  hover:shadow-xl shadow-md p-10 mb-8">
            <h2 className="text-gray-700">Butiksvyn</h2>
        <h1 className="text-2xl font-semibold leading-relaxed">(OBS, under utveckling) Visa {product.description} i Butiksvyn </h1>
        <p className="text-sm leading-relaxed">Med hjälp av butiksvyn kan du få en mer realistisk bild av dimensionerna på en produkt i olika miljöer. </p>
    <p className="text-sm leading-relaxed">        Testa välja miljöbild nedan och se hur produkten anpassar sig enligt sina riktiga dimensioner. </p>
    <p className="text-sm leading-relaxed">    Du kan dra och flytta bilden för att få en bättre uppfattning om storlek, och om du håller musen över så ser du måtten för produkten.</p>
        </div> {/* Miljöbild */}
      <div className="bg-gray-100 shadow-lg rounded-lg overflow-hidden mb-6">
        <RoomCanvas
          background={selectedEnvironment.background}
          artwork={
            product.productImages?.find((image) => image.endsWith(".png")) || // Hitta första .png-filen
            "https://placehold.co/600x400" // Fallback om ingen .png finns
          }
          productWidth={productWidth}
          productHeight={productHeight}
          name={selectedEnvironment.name}
          initialPosition={selectedEnvironment.initialPosition}
          customScaleFactor={selectedEnvironment.customScaleFactor} // Skalfaktor för miljön
         
        />
      </div>

      {/* Väljare under bilden */}
      <div className="bg-gray-100 rounded-lg shadow p-4 flex flex-wrap justify-center gap-4">
        {environments.map((env, index) => (
          <div
            key={index}
            onClick={() => setSelectedEnvironment(env)}
            className={`cursor-pointer w-24 p-2 rounded-lg flex flex-col items-center transition ${
              selectedEnvironment.name === env.name
                ? "bg-blue-500 text-white"
                : "bg-white hover:bg-gray-200"
            }`}
          >
            {/* Thumbnail */}
            <img
              src={env.thumbnail}
              alt={`${env.name} thumbnail`}
              className="w-16 h-16 object-cover rounded-lg mb-2 shadow"
            />
            {/* Namn */}
            <span className="text-sm font-medium">{env.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductViewer;
