import React, { useCallback, useState } from "react";
import { debounce } from "lodash";
import { useProductContext } from '../context/ProductContext';
import { useNavigate } from 'react-router-dom';
import SearchResults from "../components/SearchResults";

const Search = () => {
  const { searchQuery, setSearchQuery } = useProductContext();
  const [showSearchResults, setShowSearchResults] = useState(true); // Nytt state för att visa/gömma SearchResults
  const navigate = useNavigate();

  // Använd debounce för att minska frekvensen av setSearchQuery-anrop
  const handleInputChangeDebounced = useCallback(
    debounce((event) => {
      setSearchQuery(event.target.value);
      setShowSearchResults(true); // Visa SearchResults när användaren börjar skriva
    }, 300),
    []
  );

  // Hantera "Enter"-tangenttryck för att navigera till SearchResultsPage
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (searchQuery.trim()) {
        navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
        setShowSearchResults(false); // Göm SearchResults när användaren trycker Enter
      }
    }
  };

   // Hantera klick i sökrutan (fokus)
   const handleInputFocus = () => {
    if (searchQuery.trim().length >= 3) {
      setShowSearchResults(true); // Visa SearchResults vid fokus om det finns en giltig sökfråga
    }
  };

  

  return (
    <div className="w-full grow  md:w-1/2 relative flex flex-col">
      <form onSubmit={(e) => e.preventDefault()} className="">
        <div className="relative w-auto">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            {/* SVG för sökikon */}
          </div>
          <div className="search-container w-full">
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 ps-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Sök på leverantör, artikelnummer, produktnamn"
              defaultValue={searchQuery}
              autoComplete="off"
              onChange={handleInputChangeDebounced}
              onKeyDown={handleKeyDown} // Lägg till KeyDown för Enter
                            onFocus={handleInputFocus} // Visa SearchResults vid fokus
            />
          </div>
        </div>
      </form>
       {/* Visa SearchResults endast om showSearchResults är true */}
       {showSearchResults && searchQuery.length >= 3 && <SearchResults />}
    </div>
  );
};

export default Search;
