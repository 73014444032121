// searchUtils.js
import synonyms from '../db/synonyms.json'; // Importera synonymfilen

export const calculateMatchScore = (queryWords, text) => {
    if (!text) return 0;
    const lowerText = text.toLowerCase();
  
    let totalScore = 0;
  
    // För varje ord i sökqueryn, beräkna matchningspoäng
    queryWords.forEach(query => {
        const lowerQuery = query.toLowerCase();
        if (lowerText === lowerQuery) {
            // Om hela ordet matchar exakt, ge högsta poäng
            totalScore += 100;
        } else if (lowerText.includes(lowerQuery)) {
            // Beräkna matchningsprocent baserat på hur många tecken i texten som matchar ordet
            totalScore += lowerQuery.length / lowerText.length;
        }
    });
  
    return totalScore;
};
console.log(synonyms); // Kontrollera att innehållet i synonyms.json är som förväntat


const normalizedSynonyms = Object.keys(synonyms).reduce((acc, key) => {
    acc[key.toLowerCase()] = synonyms[key];
    return acc;
}, {});


export const getSynonymMatches = (query) => {
    const lowerQuery = query.toLowerCase();
    return normalizedSynonyms[lowerQuery] || [];
};
export const searchProducts = (products, searchQuery) => {
    if (!searchQuery || !searchQuery.toLowerCase) return [];
    
    const queryWords = searchQuery.split(" ");
    const synonymMatches = queryWords.flatMap(getSynonymMatches);
    const allQueries = [...queryWords, ...synonymMatches];
  console.log(synonyms); // Kontrollera att innehållet i synonyms.json är som förväntat

    const filteredProducts = products.map(product => {
        let priority = 0;
        let completenessScore = 0;
        let synonymScore = 0;
  
        allQueries.forEach(query => {
            const titleMatchScore = calculateMatchScore([query], product.title);
            const articleNumberMatchScore = calculateMatchScore([query], product.inventoryNumber);
            const descriptionMatchScore = calculateMatchScore([query], product.description);

            if (titleMatchScore > 0) priority += 50;
            if (articleNumberMatchScore > 0) priority += 50;
            if (descriptionMatchScore > 0) priority += 20;

            completenessScore += titleMatchScore + articleNumberMatchScore + descriptionMatchScore;

            product.supplierDetails.forEach(supplier => {
                const supplierMatchScore = calculateMatchScore([query], supplier.supplierName);
                if (supplierMatchScore > 0) priority += 20;
                completenessScore += supplierMatchScore;
            });

            product.salesCategories?.forEach(category => {
                const categoryMatchScore = calculateMatchScore([query], category.description);
                if (categoryMatchScore > 0) priority += 10;
                completenessScore += categoryMatchScore;
            });

            if (synonymMatches.includes(query)) {
                synonymScore += 50;
            }
        });

        const finalScore = priority + completenessScore + synonymScore;
        return { ...product, finalScore };
    });

    // Filtrera produkter med finalScore > 50 (eller annan valfri gräns)
    const matchingProducts = filteredProducts.filter(product => product.finalScore > 0) // Exkludera produkter med 0 poäng
        .filter(product => product.salesCategories && product.salesCategories.length > 0 && product.salesCategories[0].slug)
        .sort((a, b) => b.finalScore - a.finalScore);

    return matchingProducts.length > 0 ? matchingProducts : [];
};


