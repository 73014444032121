import React, { useState } from 'react';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import axios from 'axios';

const ImpersonateUser = ({ adminUid }) => {
  const [targetUid, setTargetUid] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const handleImpersonate = async () => {
    if (!targetUid) {
      alert('Vänligen ange användarens UID.');
      return;
    }
    const currentUser = auth.currentUser; // Hämtar nuvarande inloggad användare 
  console.log('currentUser:', currentUser);
if (!currentUser) {
  alert('Admin är inte inloggad.');
  return;
}
    try {
      setLoading(true);
  

      console.log('Request payload:', { targetUid, adminUid });


      // Hämta Custom Token från backend
      const response = await axios.post(
        'http://localhost:3001/api/admin/impersonate',
        { targetUid, adminUid },
        { withCredentials: true } // Viktigt om cookies används
      );

      const customToken = response.data.customToken;

      // Logga in som användaren
      await signInWithCustomToken(auth, customToken);

      alert('Du är nu inloggad som användaren.');
    } catch (error) {
      console.error('Error impersonating user:', error);
      alert('Kunde inte ta över användarens konto.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Impersonera Användare</h2>
      <input
        type="text"
        placeholder="Ange authUID"
        value={targetUid}
        onChange={(e) => setTargetUid(e.target.value)}
      />
      <button onClick={handleImpersonate} disabled={loading}>
        {loading ? 'Loggar in...' : 'Logga in som användare'}
      </button>
    </div>
  );
};

export default ImpersonateUser;
