import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../context/cart';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useProductContext } from '../context/ProductContext';
import LazyImage from './LazyImage';
const Card = ({ product: initialProduct, klippvarorData }) => {
  const { stockLevels = {}, markProductAsUpdated } = useProductContext();  const { mainCategorySlug } = useParams();
  const [product, setProduct] = useState(initialProduct); // Lokalt tillstånd för produkt
  const [animation, setAnimation] = useState(false);
  const { cartItems, addToCart, removeFromCart, setIsProductListUpdate  } = useContext(CartContext);
  const { user } = useUserContext();
  const [isUpdated, setIsUpdated] = useState(product.isUpdated);
  const [isLoading, setIsLoading] = useState(true);

  const { discountType, discountValue } = product;

/*  useEffect(() => {
    if (product.isUpdated) {
  //    console.log(`Product ${product.inventoryNumber} was updated.`);
      markProductAsUpdated(product.inventoryNumber); // Återställ `isUpdated`
      setProduct(initialProduct)
  }
}, [initialProduct]); */


 // Kontrollera om FRP-attributet finns i produkten eller i klippvarorData
 const frpAttribute = product.attributes.find(attribute => attribute.id === "FRP") ||
 klippvarorData?.attributes?.find(attribute => attribute.id === "FRP");
const viktAttribute = product.attributes.find(attribute => attribute.id === "VIKT");
const bestVara = product.attributes.find(attribute => attribute.id === "BEST" && attribute.value === "1");
  const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(product.defaultPrice);
  const frpValue = frpAttribute ? frpAttribute.value : null;
  const viktValue = viktAttribute ? viktAttribute.value : null;
const nyhet = product.salesCategories.find(category => category.slug === 'nyheter');

const klippCategorySlug = product.salesCategories.find(category => category.slug === mainCategorySlug);


const getSlugToUse = (product, mainCategorySlug, discountType, discountValue, klippvarorData) => {
  let slugToUse = '';

  // Kontrollera om klippvarorData finns
  if (product.isKlippvara) {
    slugToUse = 'klippvaror';
  }
  // Kontrollera om produkten har kampanj
  else if ((product.discountCodes && product.discountCodes.length > 0) || (discountType && discountValue > 0)) {
    slugToUse = 'kampanj';
  }
  // Om mainCategorySlug är undefined, hämta från product.salesCategories[0].slug
  else if (!mainCategorySlug && product.salesCategories && product.salesCategories.length > 0) {
    slugToUse = product.salesCategories[0].slug;
  }
  // Annars använd mainCategorySlug
  else {
    slugToUse = mainCategorySlug;
  }

  return slugToUse;
};

const slugToUse = getSlugToUse(product);

  const getColorString = (slug) => {
    switch(slug) {
      case 'kex-brod-kakor':
        return 'bg-kexbrodkakor hover:bg-opacity-75';
      case 'antipasto':
        return 'bg-antipasto hover:bg-opacity-75';
        case 'olivolja-balsamico-vinager':
        return 'bg-olivoljabalsamicovinager hover:bg-opacity-75';
            case 'pasta-ris-soppor':
        return 'bg-pastarissoppor';
            case 'kryddor-salt-smaksattare':
        return 'bg-kryddorsaltsmaksattare hover:bg-opacity-75';
            case 'saser-dressing-bbq':
        return 'bg-saserdressingbbq hover:bg-opacity-75';
             case 'marmelad-honung-curd':
        return 'bg-marmeladhonungcurd hover:bg-opacity-75';
            case 'varma-drycker':
        return 'bg-varmadrycker hover:bg-opacity-75';
            case 'dryck':
        return 'bg-dryck hover:bg-opacity-75';
            case 'chips-snacks':
        return 'bg-chipssnacks hover:bg-opacity-75';
        case 'konfektyr':
            return 'bg-konfektyr hover:bg-opacity-75';
     
      default:
        return 'bg-gray-700 hover:bg-opacity-75';
    }
  };


  const colorString = getColorString(mainCategorySlug);

  const handleNavigation = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
  };

  const getDiscountedPriceToShow = () => {
    if (!isNaN(klippvarorData?.discountedPrice) && klippvarorData?.discountedPrice !== null && klippvarorData?.discountedPrice !== undefined) {
      // Beräkna procentsats för rabatt och avrunda uppåt till närmaste heltal
     
      return klippvarorData.discountedPrice;
    }
    
    if (!isNaN(product?.discountedPrice) && product?.discountedPrice !== null && product?.discountedPrice !== undefined) {
      return product.discountedPrice;
    }
  
    // Om varken klippvarorData eller product har en giltig discountedPrice, returnera null
    return null;
  };

  const buildKlippvaraLink = (product) => {
    const encodedInventoryNumber = encodeURIComponent(product.inventoryNumber);
    return `/klippvaror/${encodedInventoryNumber}`;  };
  

const formattedDiscountedPriceToShow = getDiscountedPriceToShow();

const formattedDiscountedPrice = formattedDiscountedPriceToShow
    ? new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(formattedDiscountedPriceToShow)
    : null;

  const incrementValue = (frpValue ? parseInt(frpValue) : 1); 

// Stock values
const stockInfo = stockLevels[product?.inventoryNumber] || {};

const calculateAvailableStock = (stockInfo) => {
  if (!stockInfo || !stockInfo.aggregatedSummary) {
    return stockInfo?.quantityOnHand || 0; // Om `stockInfo` eller `aggregatedSummary` saknas, returnera `quantityOnHand` eller 0.
  }

  const { notAvailable, soAllocated, soBackOrdered, soBooked, soShipped } = stockInfo.aggregatedSummary;

  // Summera alla värden från aggregatedSummary
  const totalReserved = (notAvailable || 0) + (soAllocated || 0) + (soBackOrdered || 0) + (soBooked || 0) + (soShipped || 0);

  // Beräkna det tillgängliga lagersaldot
  return Math.max((stockInfo.quantityOnHand || 0) - totalReserved, 0); // Säkerställ att värdet inte blir negativt
};

  const getStockStatus = (available, incrementValue) => {
      if (available <= 0 ) {
          return <span className="inline-flex items-center rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700">Slut i lager</span>;
      } else if (available <= incrementValue * 2) {
          return <span className="inline-flex items-center rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-800"> {"Färre än"} {incrementValue * 2} {" i lager"}  </span>;
      } else {
          return <span className="inline-flex items-center rounded-md bg-green-100 px-1.5 py-0.5 text-xs font-medium text-green-700">I lager</span>;
      }
  };
  const placeholderImageUrl = 'https://via.placeholder.com/350';
// Funktion för att hitta en bild som innehåller "1024px" i filnamnet
const findImageWithResolution = (images) => {
  if (!images || images.length === 0) return null;
  return images.find(image => image.includes("1024px"));
};

const imageSrc = product.productThumbnails.find(image => image.includes("948")) || placeholderImageUrl;

useEffect(() => {
  const image = new Image();
  image.src = imageSrc;
  image.onload = () => setIsLoading(false);
}, [imageSrc]);


  const getPrefixedInventoryNumber = (product) => {
    // Om klippvarorData finns, använd dess inventoryNumber med "-KLIPP"
    if (product.isKlippvara) {
        return `${product.inventoryNumber}-KLIPP`;
    }
    // Om det inte är en klippvara, använd bara det vanliga inventoryNumber
    return product.inventoryNumber;
};

useEffect(() => {
    // Lyssna på förändringar i varukorgen
    const isItemInCart = cartItems.find(item => 
      item.inventoryNumber === getPrefixedInventoryNumber(product)
    );
  
    // Om produkten finns i varukorgen, uppdatera tillståndet för att visa plus/minus
    if (isItemInCart) {
      // Logik för att uppdatera komponentens tillstånd eller tvinga omrendering
    }
  }, [cartItems, product]); // Lyssna på varukorgen, produkten och klippvarorData
  

  const handleAddToCart = (product) => {
    setIsProductListUpdate(true);  // Markera att uppdateringen sker från produktlistan
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product);
    const isItemInCart = cartItems.find(item => item.inventoryNumber === prefixedInventoryNumber);
  
    let incrementValue;
  
    if (isItemInCart) {
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
    } else {
      const productFrpAttribute = product.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = productFrpAttribute ? parseInt(productFrpAttribute.value) : 1;
    }
  
    const productToAdd = { 
      ...product, 
      inventoryNumber: prefixedInventoryNumber, 
      discountedPrice: formattedDiscountedPriceToShow  // Skicka rätt pris
    };
  
    addToCart(productToAdd, klippvarorData?.lotSerialNumber, klippvarorData?.expirationDate, incrementValue, formattedDiscountedPriceToShow);
  
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 200);
  };
  
  const handleRemoveFromCart = (product) => {
    const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvarorData);
    const isItemInCart = cartItems.find(item => item.inventoryNumber === prefixedInventoryNumber);
  
    let incrementValue = 1;
    if (isItemInCart) {
      const cartFrpAttribute = isItemInCart.attributes?.find(attribute => attribute.id === "FRP");
      incrementValue = cartFrpAttribute ? parseInt(cartFrpAttribute.value) : 1;
    }
  
    removeFromCart({ ...product, inventoryNumber: prefixedInventoryNumber }, incrementValue);
  
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 200);
  };

  let supplierName = '';
  if (product.supplierDetails && product.supplierDetails.length > 0) {
      supplierName = product.supplierDetails[0].supplierName;
  }
  const supplierLink = `/supplier/${encodeURIComponent(supplierName)}`;
  const calculateDiscountPercent = (formattedPrice, formattedDiscountedPrice) => {  
    // Ta bort eventuella icke-numeriska tecken och omvandla till nummer
    const price = parseFloat(formattedPrice.replace(/[^0-9.-]+/g, ""));
    const discountedPrice = parseFloat(formattedDiscountedPrice?.replace(/[^0-9.-]+/g, ""));
    
    // Kontrollera att värden är giltiga nummer och priset inte är noll
    if (isNaN(price) || isNaN(discountedPrice) || price === 0) return 0;
  
    // Beräkna procentsatsen
    const discount = price - discountedPrice;
    const percent = Math.round((discount / price) * 100);
  
    // Avrunda procentsatsen till närmaste av 30, 50, eller 70
    const roundToNearest = [20,30, 50, 70].reduce((prev, curr) => 
      Math.abs(curr - percent) < Math.abs(prev - percent) ? curr : prev
    );
  
    return roundToNearest;
  };
  
 
  return (
      <div id={`product-${product.inventoryNumber}`} className="card p-4 exclude-sidebar border border-gray-200 dark:border-gray-700 m-2 shadow dark:text-white relative" key={product.inventoryNumber}>
           {nyhet && (
                  <span className="inline-flex m-auto items-center rounded-md bg-gray-800 px-2 py-1 text-xs font-medium text-white">
                      Nyhet!
                  </span>
              )}
                 {bestVara && user && product.status !== 'NoSales' && (
                  <span className=" mx-2  inline-flex items-center rounded-md bg-orange-400  px-2 py-1 text-xs font-medium text-orange-800">
                      Beställningsvara
                  </span>
              )}
          {supplierName && (
              <div className='supplier-name px-5  '>
                  <Link to={supplierLink}>
                      {isLoading ? <Skeleton width={100} /> : supplierName}
                  </Link>
              </div>
          )}
          
          <div className={`flex relative rounded-lg  dark:bg-gray-800 p-2 flex-col ${isLoading ? 'opacity-50' : ''}`}>
              { (product.discountType && product.discountValue > 0) || (discountType ) && (
                  <span className="inline-block max-w-min  rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                      KAMPANJ
                  </span>
              )}
             {product.discountType === 'percentage'  ? (
        <span className="font-semibold text-sm p-2  bg-orange-500 top-0 right-0	text-white rounded absolute z-10 ">- {calculateDiscountPercent(formattedPrice, formattedDiscountedPrice)} %</span>
      ) : null}
         
              {product.isKlippvara ? (
                  <span className='absolute z-10 top-0 left-0 m-2 rounded-md bg-red-100 px-1.5 py-0.5 text-xs font-medium text-red-700'>BF: {new Date(product?.expirationDate).toISOString().split('T')[0]}</span>
              ) : null}
               {product.isKlippvara  ? (
        <span className="font-semibold text-sm p-2  bg-red-700 top-0 right-0	text-white rounded absolute z-10 ">- {calculateDiscountPercent(formattedPrice, formattedDiscountedPrice)} %</span>
      ) : null}
           {
  product.isKlippvara ? (
    <Link to={buildKlippvaraLink(product)} className="relative mx-1 mt-3 flex h-60 rounded-xl justify-center" onClick={handleNavigation}>
      {isLoading ? <Skeleton height={240} width={350} /> : <img src={imageSrc} alt={product.description} className="card-img object-contain h-auto"  loading="lazy"  />}
    </Link>
  ) : (
    <Link to={`/cat/${slugToUse}/product/${product.inventoryNumber}`} className="relative mx-1 mt-3 flex max-h-60 rounded-xl justify-center object-contain" onClick={handleNavigation}>
      {isLoading ? <Skeleton height={240} width={350} /> : <LazyImage className='min-h-100 object-contain max-h-40' src={imageSrc} alt={product.description}  size={400}  />
     }
    </Link>
  )
}

     
             
              <div className="card-details mt-4 px-5 pb-5 dark:text-white">
              <Link to={`/cat/${slugToUse}/product/${product.inventoryNumber}`} ><h3 className="card-title text-md text-slate-900 dark:text-white transform hover:scale-105 hover:origin-center  transition  duration-100 ease-in-out ">
                      {isLoading ? <Skeleton width={200} /> : product.description}
                  </h3>
                  </Link>
                  <div className="card-productdetail text-sm text-slate-900 dark:text-white">
                      <p>{isLoading ? <Skeleton width={150} /> : `${product.inventoryNumber}`}</p>
                      {user && !isLoading && product.status !== 'NoSales' ? (
  <>
    <div className="card-price mt-2 mb-5 flex items-start justify-between flex-col">
      {formattedDiscountedPrice ? (
        <>
          <div className='text-2xl font-bold text-red-900 dark:text-red-500'>{formattedDiscountedPrice}</div>
          <div className="text-sm text-slate-900 dark:text-gray-400 line-through">
            {formattedPrice}
          </div>
        </>
      ) : (
        <div className='text-2xl font-bold text-slate-900 dark:text-white'>{formattedPrice}</div>
      )}
    </div>

    {/* Kontrollera om det inte är en bestVara innan du renderar stockStatus */}
    {!bestVara && (
      <>
        {product.isKlippvara ? (
          <div className='stock'>
            {getStockStatus(product.klippvarorData.quantity, incrementValue)}
            {/* Om du vill lägga till mer info baserat på klippvarorData */}
          </div>
        ) : (
          <div className='stock'>
          {getStockStatus(calculateAvailableStock(stockInfo), incrementValue)}
          </div>
        )}
      </>
    )}
  </>
) : null}
                   <div className='mt-6 flex justify-between items-center'>
  {user ? (
    product.status === 'NoSales' ? (
      <span className='text-xl font-bold text-red-800'>Ej beställningsbar</span>
    ) : (
      !cartItems.find(item => 
        item.inventoryNumber === getPrefixedInventoryNumber(product, klippvarorData)
      ) ? (
        <button
          className={`${colorString} px-4 py-2 text-white text-md md:text-xs w-full md:w-auto transform active:scale-75 transition duration-100 ease-in-out font-bold uppercase rounded bg-gradient-to-br from-slate-800 to-gray-900 focus:bg-opacity-50`}
          onClick={() => handleAddToCart(product)}
          disabled={isLoading}
        >
          Lägg i varukorg
        </button>
      ) : (
        <div className="flex gap-4 items-center dark:bg-gray-700 justify-between w-full mx-auto md:mx-0 md:w-max">
          <button
            className="px-4 py-2 bg-gradient-to-r w-1/3 h-14 max-w-16 md:w-auto md:h-auto md:max-w-unset from-red-400 to-red-500 text-white text-2xl md:text-xs font-bold uppercase transform active:scale-75 transition-transform rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
            onClick={() => handleRemoveFromCart(product)}
            disabled={isLoading}
          >
            -
          </button>
          <p className={`text-gray-600 text-xl md:text-sm text-center dark:text-white ${animation ? 'animate-pulse-custom' : ''}`}>
            {cartItems.find(item => 
              item.inventoryNumber === getPrefixedInventoryNumber(product, klippvarorData)
            ).quantity}
          </p>
          <button
            className="px-4 py-2 bg-gradient-to-r w-1/3 h-14 max-w-16 md:w-auto md:h-auto from-green-400 to-green-500 transform active:scale-75 transition-transform text-white text-2xl md:text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
            onClick={() => handleAddToCart(product)}
            disabled={isLoading}
          >
            +
          </button>
        </div>
      )
    )
  ) : (
    <Link
      to={`/cat/${mainCategorySlug}/product/${product.inventoryNumber}`}
      className="px-4 py-2 bg-golden text-white text-xs font-bold uppercase rounded hover:bg-golden/50 focus:outline-none focus:bg-blue-700"
      onClick={handleNavigation}
    >
      Läs mer
    </Link>
  )}
</div>

                      </div>
                  </div>
              </div>
          </div>
   
  );
};

export default Card;

