import React, { useEffect, useState, useMemo } from 'react';
import { useProductContext } from '../context/ProductContext';
import Products from '../Products/Products';
import { searchProducts } from '../services/searchUtils';

const SearchResultsPage = () => {
  const { products, discountedProducts, searchQuery } = useProductContext();
  const [searchResults, setSearchResults] = useState([]);

  // Memoisera tillgängliga produkter: använd discountedProducts om de finns, annars products
  const availableProducts = useMemo(() => {
    return discountedProducts.length > 0 ? discountedProducts : products;
  }, [products, discountedProducts]);

  // Kör sökningen när searchQuery eller availableProducts ändras
  useEffect(() => {
    if (searchQuery) {
      const results = searchProducts(availableProducts, searchQuery);
      setSearchResults(results.slice(0, 80)); // Begränsa till 80 resultat
    } else {
      setSearchResults([]);
    }
  }, [availableProducts, searchQuery]);

  return (
    <div className='mt-32'>
      <h1 className="text-3xl font-extrabold dark:text-white pl-5 pt-12">
        Visar alla resultat för "{searchQuery}"
      </h1>
      {searchResults.length > 0 ? (
        <Products products={searchResults} />
      ) : (
        <p>Inga resultat hittades</p>
      )}
    </div>
  );
};

export default SearchResultsPage;
