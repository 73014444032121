import axios from "axios";

// Funktion för att hämta access token
const getAccessToken = async () => {
  try {
    const response = await axios.post('/api/proxy', null, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const accessToken = response.data.accessToken;
    console.log(accessToken);
    return accessToken;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
};

export default getAccessToken;

export const sendOrder = async (orderData) => {
  try {
    const response = await axios.post('/api/send-order', orderData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error sending order:', error);
    throw error;
  }
};


