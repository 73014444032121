import React, { useState, useEffect } from 'react';
import { useUserContext } from '../context/userContext';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';
import GastroLogga from "../imgs/Gastronomileverantoren_logotyp.png";
import { getAuth, setPersistence, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
import { authentication } from '../firebase/firebase'; // Detta är korrekt om du exporterar getAuth(app) som "authentication"

function Login() {
  const { login, user, logout, error, loading } = useUserContext();
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState('');
  const [identifier, setIdentifier] = useState(''); // Hanterar både e-post eller kundnummer
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
  
      // Sätt persistens baserat på "Kom ihåg mig"-checkbox
      if (rememberMe) {
        await setPersistence(authentication, browserLocalPersistence); // Sparar token i localStorage
      } else {
        await setPersistence(authentication, browserSessionPersistence); // Sparar token i sessionStorage
      }
  
      await login(identifier, password); // Antar att login är signInWithEmailAndPassword
    }
  

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };



  useEffect(() => {
    if (user) {
      navigate('/handla');
    }
  }, [user, navigate]);


  if (loading) {
    return (
      <div className="loading-overlay w-full h-screen">
      <div className="loading-indicator text-center pt-20">
        <div className="spinner text-center mx-auto"> <svg aria-hidden="true" class="w-20 mx-auto my-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg></div> 
        <h3 className='text-4xl font-bold text-center'>Du loggas in...</h3>
       
      </div>
    </div>
    );
  }

  return (
    <div className='dark:bg-gray-800 dark:text-white'>
      {user ? (<>
      
        <div className='container-full p-20 '>
        <h1 className="text-2xl text-center my-10 text-bold text-slate-900">Inloggad som {user.displayName} - Omdirigerar till butik...</h1>
          <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
    
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
       
          <p className="text-sm text-blue-700">Du är inloggad som {user.email}</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
           
            <button className='whitespace-nowrap font-medium text-blue-700 hover:text-blue-600' onClick={logout}>Logga ut</button>
              <span aria-hidden="true"> &rarr;</span>
            
          </p>
        </div>
      </div>
    </div>
    </div>  
    </>
      
      ) : (
        <div className="flex min-h-full flex-1 flex-col col-span-2 container mx-auto w-full md:w-1/2 justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto md:w-full sm:max-w-lg">
          <img
            className="mx-auto h-64 w-auto"
            src={GastroLogga}
            alt="GastronomiLeverantören"
          />
             
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
        
           Logga in
          </h2>
        </div>
        
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"></div>
        <form onSubmit={handleLogin}>
          <div className="mt-2">
          <label htmlFor="identifier">E-postadress eller Kundnummer</label>
        <input
          type="text"
          id="identifier"
          autoComplete="username" // Lämpligt för både e-post och kundnummer
          className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:border-gray-600"
          placeholder="E-post eller kundnummer"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)} // Uppdaterar identifieringsvärdet
        />
      </div>
      <div className="mt-2 relative">
          <input           type={showPassword ? "text" : "password"}
 autoComplete="current-password" name='password' className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm dark:text-white sm:leading-6" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Lösenord" />
          <button
          type="button"
          onClick={toggleShowPassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
           
            <FontAwesomeIcon icon={faEye} />
          )}
        </button></div>
          
          <button className='rounded-md bg-white px-3 py-2 text-sm font-semibold my-5 text-gray-900 shadow-sm ring-1 ring-inset dark:border-gray-600 hover:bg-gray-50 dark:bg-slate-600 dark:text-gray-200' type="submit">Logga in</button>
          <div className="mt-2">
  <label className="flex items-center">
    <input
      type="checkbox"
      checked={rememberMe}
      onChange={(e) => setRememberMe(e.target.checked)}
      className="mr-2"
    />
    Kom ihåg mig
  </label>
</div>{error &&  <div className="rounded-md bg-red-50 dark:bg-transparent  p-4">
      <div className="flex">
        <div className="flex-shrink-0">
        
        </div>
        <div className="ml-3 ">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-500">Något är fel vid inloggningen:</h3>
          <div className="mt-2 text-sm text-red-700 dark:text-red-500">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>{error}</li>
             
            </ul>
          </div>
        </div>
      </div>
    </div>}
        </form>
        
          <p className="mt-10 text-center dark:text-gray-300 text-sm text-gray-600">
            Glömt lösenordet?{' '}
            <Link to="/forgot-password" className="font-semibold leading-6 text-yellow-600 hover:text-yellow-800">
              Klicka här för att återställa ditt lösenord
            </Link>
          </p>
        </div>
     
      )}
    </div>
  );
}

export default Login;