import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { CartContext } from "../context/cart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlateUtensils, faShoppingCart, faUser } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';
import { useUserContext } from "../context/userContext";

const BottomNav = ({ toggleSidebar, closeSidebar }) => {
  const { user } = useUserContext();
  const { cartItems, toggleCart } = useContext(CartContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // Nytt tillstånd för att spåra sidomenyns status

  // Toggla sidomenyn (öppna/stäng) vid tryck på "Sortiment"-knappen
  const handleSidebarToggle = () => {
    toggleSidebar();
    setIsSidebarOpen(!isSidebarOpen);  // Uppdatera statusen för sidomenyn
  };

  
  // Räkna ut det totala antalet artiklar (quantity) i varukorgen
  const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);


  // Stäng sidomenyn om den är öppen vid navigering
  const handleNavigationClick = () => {
   
    closeSidebar();  // Stäng sidomenyn
  
  };

  return (
    <div className="fixed bottom-0 left-0 z-50 w-screen h-20 bg-gradient-to-br from-slate-800 to-gray-900 tex-twhite border-t border-gray-700 dark:bg-gray-700 dark:border-gray-600 visible lg:invisible">
      <div className="grid h-full grid-cols-3 w-auto auto-cols-auto font-medium">
        
        {/* Start-knapp */}
        <Link 
          to="/" 
          onClick={handleNavigationClick}  // Stänger sidebar om den är öppen
          className="inline-flex flex-col items-center justify-center px-5 border-gray-700 border-x hover:bg-gray-700 dark:hover:bg-gray-800 group dark:border-gray-600"
        >
          <svg className="w-5 h-5 mb-2 text-gray-200 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
          </svg>
          <span className="text-sm text-gray-200 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-blue-500">Start</span>
        </Link>

        {/* Sortiment-knapp */}
        <button 
          type="button" 
          onClick={handleSidebarToggle}  // Toggla sidomenyn vid tryck på "Sortiment"
          className="inline-flex flex-col items-center justify-center bg-gradient-to-r from-amber-300 to-amber-600 px-5 border-e border-gray-700 hover:bg-gray-700 dark:hover:bg-gray-800 group dark:border-gray-600"
        >
          <FontAwesomeIcon icon={faPlateUtensils} className="text-gray-900 group-hover:text-white" />
          <span className="text-sm text-gray-900 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500">Sortiment</span>
        </button>

        {/* Varukorg/Logga in-knapp */}
        {user ? (
          <button 
            onClick={() => {
              toggleCart();
              handleNavigationClick();  // Stäng sidomenyn om den är öppen
            }} 
            type="button" 
            className="inline-flex flex-col items-center text-gray-200 justify-center px-5 hover:bg-gray-700 dark:hover:bg-gray-800 group"
          >
            <FontAwesomeIcon icon={faShoppingCart} className="nav-icons text-sm text-gray-200 dark:text-gray-400 group-hover:text-amber-500 dark:group-hover:text-amber-500" /> 
            <span className=" group-hover:text-amber-500 ">{cartItems.length} varor ({totalQuantity})</span>
          </button>
        ) : (
          <Link 
            to="/login" 
            onClick={handleNavigationClick}  // Stänger sidebar om den är öppen
            className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-700 dark:hover:bg-gray-800 group text-gray-200"
          >
            <FontAwesomeIcon icon={faUser} className="nav-icons text-sm text-gray-200 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" /> 
            Logga in
          </Link>
        )}
      </div>
    </div>
  );
};

export default BottomNav;
