import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import Products from '../Products/Products';

const SinglePromotion = ({ categorySlug, supplierSlug }) => {
  const [promotion, setPromotion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchPromotion = async () => {
      try {
        const promotionsRef = collection(db, 'promotionalContent');
        const promotionSnapshot = await getDocs(promotionsRef);

        if (!promotionSnapshot.empty) {
          const promotionData = promotionSnapshot.docs
            .map((doc) => doc.data())
            .find(
              (promo) =>
                (categorySlug && promo.categorySlug === categorySlug) ||
                (supplierSlug && promo.supplierSlug === supplierSlug)
            );

          setPromotion(promotionData || null); // Sätt till null om ingen matchar
        }
      } catch (error) {
        console.error('Error fetching promotion:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromotion();
  }, [categorySlug, supplierSlug]);

  if (isLoading) {
    return <p>Laddar kampanj...</p>;
  }

  if (hasError || !promotion) {
    return null; // Visa inget om ingen kampanj hittas
  }

  return (
    <div className="promotion-section mt-32 p-4">
      {/* Rendera kampanjens banner */}
      <div
        className="relative max-h-96 min-h-64 flex flex-col justify-center items-center text-center bg-cover bg-center"
        style={{ backgroundImage: `url(${promotion.bannerImage})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <h1 className="relative z-10 text-4xl font-extrabold text-white">{promotion.title}</h1>
        <p className="text-lg drop-shadow-lg z-10 text-white py-5">{promotion.description}</p>
        {promotion.slug && (
          <Link
            to={`/kampanjer/${promotion.slug}`}
            className="relative z-10 mt-4 bg-orange-400 transform hover:scale-110 hover:origin-center transition duration-100 ease-in-out drop-shadow-lg inline-block text rounded font-semibold text-white px-5 py-3 hover:underline"
          >
            Gå till kampanjen
          </Link>
        )}
      </div>
      {/* Rendera produkter i kampanjen */}
      {promotion.products && (
        <div className="products mt-8">
          {/* Rendera dina produkter, använd din Products-komponent */}
          <Products products={promotion.products} />
        </div>
      )}
    </div>
  );
};

export default SinglePromotion;
