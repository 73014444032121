// utils/stockCalculations.js
export const calculateAvailableStock = (stockInfo) => {
    if (!stockInfo || !stockInfo.aggregatedSummary) {
        return stockInfo?.quantityOnHand || 0;
    }

    const { notAvailable, soAllocated, soBackOrdered, soBooked, soShipped } = stockInfo.aggregatedSummary;

    const totalReserved = (notAvailable || 0) + (soAllocated || 0) + (soBackOrdered || 0) + (soBooked || 0) + (soShipped || 0);

    return Math.max((stockInfo.quantityOnHand || 0) - totalReserved, 0);
};
