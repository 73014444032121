import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // För att fånga upp slug från URL:en
import { db } from '../firebase/firebase';
import { getDocs, collection, query, where } from 'firebase/firestore';
import Products from '../Products/Products'; // Komponent för att visa produkterna
import { Link } from 'react-router-dom';

const PromotionPage = () => {
  const { slug } = useParams(); // Hämta slugen från URL:en
  const [promotion, setPromotion] = useState(null); // För att lagra kampanjen
  const [isLoading, setIsLoading] = useState(true); // Laddningsindikator
  const [hasError, setHasError] = useState(false); // Felhantering

  useEffect(() => {
    const fetchPromotionBySlug = async () => {
      try {
        const promotionsRef = collection(db, 'promotionalContent');
        const q = query(promotionsRef, where('slug', '==', slug)); // Hämta kampanjen baserat på slug
        const promotionSnapshot = await getDocs(q);
  
        if (!promotionSnapshot.empty) {
          const promotionData = promotionSnapshot.docs[0].data();
          setPromotion(promotionData);
        } else {
          setHasError(true);
        }
      } catch (error) {
        console.error('Error fetching promotion:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchPromotionBySlug();
  }, [slug]);

  if (isLoading) {
    return <p>Laddar kampanj...</p>; // Laddningsindikator
  }

  if (hasError || !promotion) {
    return <p>Kampanjen kunde inte hittas.</p>; // Felmeddelande om något går fel
  }

  return (
    <div className="promotion-section p-8 mt-40">
       <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to={`/kampanj/${slug}`}>{promotion.title}</Link>
                        </div>
                    </li>
                </ol>
            </nav>
      {/* Rendera kampanjens banner */}
      <div
           className="relative max-h-96 min-h-64 flex flex-col justify-center items-center text-center bg-cover bg-center"
           style={{ backgroundImage: `url(${promotion.bannerImage})` }}
         >
           {/* Overlay för att mörka ner bakgrunden lite */}
           <div className="absolute inset-0 bg-black opacity-50"></div>
           
           {/* Innehållet centrerat i mitten av sektionen */}
           <h1 className="relative z-10 text-4xl font-extrabold text-white">
             {promotion.title}
           </h1>
   {/* Rendera kampanjbeskrivning */}
   <p className='text z-10 text-white py-5'>{promotion.description}</p>
          {/* Länk till kampanjsidan baserat på slug */}
        
          </div>
      {/* Rendera kampanjens produkter */}
      {promotion.products && promotion.products.length > 0 ? (
        <Products products={promotion.products} />
      ) : (
        <p>Inga produkter tillgängliga för denna kampanj.</p>
      )}

      
    </div>
  );
};

export default PromotionPage;
