
export const processProduct = (product) => {
    const stringToSlug = (str) => {
      return str
        .toLowerCase()
        .replace(/[åä]/g, 'a')
        .replace(/[ö]/g, 'o')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .trim();
    };
  
    const removeSupplierPrefix = (title, prefixes) => {
      if (!title) return title;
      const words = title.split(" ");
      const firstWord = words[0].trim().toLowerCase();
      return prefixes[firstWord] !== undefined ? words.slice(1).join(" ") : title;
    };
  
    const supplierPrefixes = {
        "gran": "", // Tar bort "Gran" från produktnamn
        "ets": "",  // Tar bort "Eco" från produktnamn
        "leo": "", // Tar bort "Super" från produktnamn
        "alma": "", // Tar bort "Super" från produktnamn
        "st": "", // Tar bort "Gran" från produktnamn
        "LC": "",  // Tar bort "Eco" från produktnamn
        "belb": "", // Tar bort "Super" från produktnamn
        "bel": "", // Tar bort "Super" från produktnamn
        "fallot": "", // Tar bort "Gran" från produktnamn
        "savini": "",  // Tar bort "Eco" från produktnamn
        "nonna": "", // Tar bort "Super" från produktnamn
        "granata": "", // Tar bort "Super" från produktnamn
        "bont": "", // Tar bort "Gran" från produktnamn
        "arti": "",  // Tar bort "Eco" från produktnamn
        "db": "", // Tar bort "Super" från produktnamn
        "val": "", // Tar bort "Super" från produktnamn
        "cart": "", // Tar bort "Gran" från produktnamn
        "dunc": "",  // Tar bort "Eco" från produktnamn
        "far": "", // Tar bort "Super" från produktnamn
        "deli": "", // Tar bort "Super" från produktnamn
        "biscu": "", // Tar bort "Super" från produktnamn
        "bruyere": "", // Tar bort "Gran" från produktnamn
        "whit": "",  // Tar bort "Eco" från produktnamn
        "dhau": "", // Tar bort "Super" från produktnamn
        "pau": "", // Tar bort "Super" från produktnamn
        "belli": "", // Tar bort "Gran" från produktnamn
        "sierra": "",  // Tar bort "Eco" från produktnamn
        "lc": "", // Tar bort "Super" från produktnamn
        "torres": "", // Tar bort "Super" från produktnamn
        "nor": "", // Tar bort "Gran" från produktnamn
        "lat": "",  // Tar bort "Eco" från produktnamn
        "lap": "", // Tar bort "Super" från produktnamn
        "ven": "", // Tar bort "Super" från produktnamn
        "ib": "", // Tar bort "Gran" från produktnamn
        "campo": "",  // Tar bort "Eco" från produktnamn
        "fancy": "", // Tar bort "Super" från produktnamn
        "elavion": "", // Tar bort "Super" från produktnamn
        "corex": "", // Tar bort "Super" från produktnamn
        "anko": "", // Tar bort "Gran" från produktnamn
        "ducs": "",  // Tar bort "Eco" från produktnamn
        "bm": "", // Tar bort "Super" från produktnamn
        "nomu": "", // Tar bort "Super" från produktnamn
        "munch": "", // Tar bort "Gran" från produktnamn
        "green": "",  // Tar bort "Eco" från produktnamn
        "food": "", // Tar bort "Super" från produktnamn
        "and": "", // Tar bort "Super" från produktnamn
        "mrsd": "", // Tar bort "Gran" från produktnamn
        "don": "",  // Tar bort "Eco" från produktnamn
        "atk": "", // Tar bort "Super" från produktnamn
        "lacasa": "", // Tar bort "Super" från produktnamn
        "brez": "", // Tar bort "Gran" från produktnamn
        "can": "",  // Tar bort "Eco" från produktnamn
        "australian": "", // Tar bort "Super" från produktnamn
        "thu": "", // Tar bort "Super" från produktnamn
        "ml": "", // Tar bort "Gran" från produktnamn
        "baru": "",  // Tar bort "Eco" från produktnamn
        "åhus": "", // Tar bort "Super" från produktnamn
        "mac": "", // Tar bort "Super" från produktnamn
        "effervé": "", // Tar bort "Gran" från produktnamn
        "orna": "",  // Tar bort "Eco" från produktnamn
        "himmelstund": "", // Tar bort "Super" från produktnamn
        "rscued": "", // Tar bort "Super" från produktnamn
        "rubio": "", // Tar bort "Gran" från produktnamn
        "pop": "",  // Tar bort "Eco" från produktnamn
        "comptoir": "", // Tar bort "Super" från produktnamn
        "choc": "", // Tar bort "Super" från produktnamn
        "choco": "", // Tar bort "Super" från produktnamn
        "luigia": "", // Tar bort "Super" från produktnamn
        "riv": "", // Tar bort "Gran" från produktnamn
        "caf": "",  // Tar bort "Eco" från produktnamn
        "ct": "", // Tar bort "Super" från produktnamn
        "gar": "", // Tar bort "Super" från produktnamn
        "house": "", // Tar bort "Gran" från produktnamn
        "prez": "",  // Tar bort "Eco" från produktnamn
        "coll": "", // Tar bort "Super" från produktnamn
        "mont": "", // Tar bort "Super" från produktnamn
        "gk": "", // Tar bort "Gran" från produktnamn
        "kk": "",  // Tar bort "Eco" från produktnamn
        "amatller": "", // Tar bort "Super" från produktnamn
        "rj": "", // Tar bort "Super" från produktnamn
        "gal": "", // Tar bort "Super" från produktnamn
        "kent": "", // Tar bort "Super" från produktnamn
        // Lägg till fler prefix efter behov
    };
  
    return {
      ...product,
      description: removeSupplierPrefix(product.description, supplierPrefixes),
      salesCategories: product.salesCategories.map((category) => ({
        ...category,
        slug: stringToSlug(category.description),
      })),
      supplierDetails: product.supplierDetails.map((supplier) => ({
        ...supplier,
        slug: stringToSlug(supplier.supplierName),
      })),
    };
  };
  