import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { getDocs, collection } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Importera Link för att skapa länkar till kampanjsidor
import Products from '../Products/Products'; // Din komponent för att visa produkterna

const PromotionalContent = () => {
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const promotionsRef = collection(db, 'promotionalContent');
        const promotionSnapshot = await getDocs(promotionsRef);

        if (!promotionSnapshot.empty) { 
          // Hämta kampanjdata om det finns
          const promotionData = promotionSnapshot.docs.map(doc => doc.data());
          setPromotions(promotionData);
        }

      } catch (error) {
        console.error('Error fetching promotions:', error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPromotions();
  }, []);

  if (isLoading) {
    return <p>Laddar kampanjer...</p>; 
  }

  if (hasError) {
    return <p>Fel inträffade vid hämtning av kampanjer.</p>;
  }

  if (promotions.length === 0) {
    return ' ';
  }

  return (
    <div>
      {promotions.map((promo, index) => (
         <div key={index} className="promotion-section mt-32 p-4">
         {/* Rendera kampanjens banner */}
         <div
           className="relative max-h-96 min-h-64 flex flex-col justify-center items-center text-center bg-cover bg-center"
           style={{ backgroundImage: `url(${promo.bannerImage})` }}
         >
           {/* Overlay för att mörka ner bakgrunden lite */}
           <div className="absolute inset-0 bg-black opacity-50"></div>
           
           {/* Innehållet centrerat i mitten av sektionen */}
           <h1 className="relative z-10 text-4xl font-extrabold drop-shadow-2xl text-white">
             {promo.title}
           </h1>
   {/* Rendera kampanjbeskrivning */}
   <p className='text-lg drop-shadow-lg z-10 text-white py-5'>{promo.description}</p>
          {/* Länk till kampanjsidan baserat på slug */}
           {promo.slug && (
             <Link
               to={`/kampanjer/${promo.slug}`}
               className="relative z-10 mt-4 bg-orange-400 transform hover:scale-110 hover:origin-center transition  duration-100 ease-in-out  drop-shadow-lg inline-block text rounded font-semibold text-white px-5 py-3 hover:underline"
             >
               Gå till kampanjen
             </Link>
           )}
          </div>
         
          {/* Rendera produkter i kampanjen */}
          {promo.products && <Products products={promo.products} />}
        </div>
      ))}
    </div>
  );
};

export default PromotionalContent;
