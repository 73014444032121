import { createContext, useState, useEffect, useMemo } from 'react';
import { useProductContext } from './ProductContext';
import { useUserContext } from './userContext';
import { doc, setDoc, updateDoc, getDoc, onSnapshot, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });
  const [isProductListUpdate, setIsProductListUpdate] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { products, discounts, klippvarorData } = useProductContext();
  const { user } = useUserContext();
  const [campaigns, setCampaigns] = useState([]);
  const [animation, setAnimation] = useState(false);
  const [campaignsDiscount, setCampaignsDiscount] = useState(false);
  const [isCombinedDelivery, setIsCombinedDelivery] = useState(() => {
    const storedValue = localStorage.getItem('isCombinedDelivery');
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // Synkronisera varukorg med Firestore
  const saveCartToFirestore = async (cart) => {
    if (!user) return;
    const cartDocRef = doc(db, 'carts', user.uid);
    try {
      await setDoc(cartDocRef, { items: cart }, { merge: true });
    } catch (error) {
      console.error('Error saving cart to Firestore:', error);
    }
  };

  // Ladda varukorg från Firestore vid användarinloggning
  useEffect(() => {
    if (user) {
      const cartDocRef = doc(db, 'carts', user.uid);
      const unsubscribe = onSnapshot(cartDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setCartItems(docSnapshot.data().items || []);
        }
      });

      return () => unsubscribe();
    }
  }, [user]);


  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const campaignsSnapshot = await getDocs(collection(db, 'promotionalContent'));
        const campaignsData = campaignsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCampaigns(campaignsData);
        console.log('Laddade kampanjer:', campaignsData); // Logga för att bekräfta data
      } catch (error) {
        console.error('Fel vid hämtning av kampanjer:', error);
      }
    };
  
    fetchCampaigns();
  }, []);
  


  // Sparar varukorg till localStorage (för fallback)
  const saveCartToLocalStorage = (items) => {
    localStorage.setItem('cartItems', JSON.stringify(items));
  };

  useEffect(() => {
    saveCartToLocalStorage(cartItems);
  }, [cartItems]);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const getPrefixedInventoryNumber = (product) => {
    if (klippvarorData?.lotSerialNumber && klippvarorData?.expirationDate) {
      return `${product.inventoryNumber}`;
    }
    return product.inventoryNumber;
  };

  const productIncrements = useMemo(() => products.map(product => ({
    inventoryNumber: product.inventoryNumber,
    increment: parseInt(product.attributes.find(attribute => attribute.id === "FRP")?.value) || 1
  })), [products]);

  const addToCart = (item, lotSerialNumber, expirationDate, quantity = 1, formattedDiscountedPrice, isReorder = false) => {
    const prefixedInventoryNumber = item.inventoryNumber;
  
    setCartItems((prevCartItems) => {
      const isItemInCart = prevCartItems.find(
        (cartItem) => cartItem.inventoryNumber === prefixedInventoryNumber
      );
  
      const incrementValue = isItemInCart
        ? parseInt(isItemInCart.attributes?.find((attr) => attr.id === 'FRP')?.value || 1)
        : parseInt(item.attributes?.find((attr) => attr.id === 'FRP')?.value || 1);
  
      const price = formattedDiscountedPrice ?? item.defaultPrice;
      const finalQuantity = isReorder ? quantity : incrementValue;
  
      let updatedCart;
      if (isItemInCart) {
        updatedCart = prevCartItems.map((cartItem) =>
          cartItem.inventoryNumber === prefixedInventoryNumber
            ? { ...cartItem, quantity: cartItem.quantity + finalQuantity, price }
            : cartItem
        );
      } else {
        updatedCart = [
          ...prevCartItems,
          {
            ...item,
            quantity: finalQuantity,
            inventoryNumber: prefixedInventoryNumber,
            price,
            ...(lotSerialNumber && { lotSerialNumber }),
            ...(expirationDate && { expirationDate }),
          },
        ];
      }
  
      // Tillämpa kampanjlogik
      const cartWithDiscounts = applyBuyXPayForYDiscount(updatedCart, campaigns);
  
      // Spara uppdaterad varukorg i Firestore
      saveCartToFirestore(cartWithDiscounts);
  
      return cartWithDiscounts;
    });
  };
  
  
  const removeFromCart = (item) => {
    const prefixedInventoryNumber = getPrefixedInventoryNumber(item);
  
    setCartItems((prevCartItems) => {
      const updatedCart = prevCartItems
        .map((cartItem) => {
          if (cartItem.inventoryNumber === prefixedInventoryNumber) {
            const incrementValue = parseInt(
              cartItem.attributes?.find((attr) => attr.id === 'FRP')?.value || 1,
              10
            );
  
            // Minska kvantiteten med ett inkrement
            const newQuantity = cartItem.quantity - incrementValue;
  
            // Om kvantiteten blir noll eller mindre, filtrera bort produkten
            if (newQuantity > 0) {
              return { ...cartItem, quantity: newQuantity };
            } else {
              return null; // Markera för borttagning
            }
          }
          return cartItem;
        })
        .filter((cartItem) => cartItem !== null); // Filtrera bort produkter med noll kvantitet
  
      // Tillämpa kampanjlogik
      const cartWithDiscounts = applyBuyXPayForYDiscount(updatedCart, campaigns);
  
      // Spara uppdaterad varukorg i Firestore
      saveCartToFirestore(cartWithDiscounts);
  
      return cartWithDiscounts;
    });
  };
  

  const clearCart = () => {
    setCartItems([]);
    setCampaignsDiscount(0); // Nollställ kampanjrabatten
    if (user) {
      const cartDocRef = doc(db, 'carts', user.uid);
      updateDoc(cartDocRef, { items: [] });
    }
  };

  const getShippingStatus = (cartTotal) => {
    const freeShippingThreshold = 6500;
    const amountRemaining = freeShippingThreshold - cartTotal;
    const percentage = Math.min((cartTotal / freeShippingThreshold) * 100, 100);
    const isFreeShipping = cartTotal >= freeShippingThreshold;

    return { cartTotal, amountRemaining, percentage, isFreeShipping };
  };

 

  const bestItems = cartItems.filter(item =>
    item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "1")
  );

  const nonBestItems = cartItems.filter(item =>
    !item.attributes.some(attribute => attribute.id === "BEST") || item.attributes.some(attribute => attribute.id === "BEST" && attribute.value === "0")
  );

  const getBestItemsTotal = () => {
    return bestItems.reduce((total, item) => {
      const price = item.discountedPrice ?? item.defaultPrice;
      return total + price * item.quantity;
    }, 0) - totalDiscount;
  };

  const getNonBestItemsTotal = () => {
    return nonBestItems.reduce((total, item) => {
      const price = item.defaultPrice;
      return total + price * item.quantity;
    }, 0) - totalDiscount;
  };


  const removeItemCompletely = (itemToRemove) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.filter((item) => item.inventoryNumber !== itemToRemove.inventoryNumber);
      saveCartToFirestore(updatedCart);
      return updatedCart;
    });
  };
  const applyBuyXPayForYDiscount = (cartItems, campaigns) => {
    const updatedCart = [...cartItems];
    let totalCampaignDiscount = 0; // Nollställ total rabatt
  
    campaigns.forEach((campaign) => {
      const { products } = campaign;
  
      if (products?.length > 0) {
        // Hitta alla produkter i varukorgen som ingår i kampanjen
        const eligibleItems = updatedCart.filter((item) =>
          products.some((product) => product.inventoryNumber === item.inventoryNumber)
        );
  
        if (eligibleItems.length > 0) {
          // Beräkna det totala antalet lådor för alla produkter i kampanjen
          const totalBoxes = eligibleItems.reduce((sum, item) => {
            const incrementValue = parseInt(
              item.attributes?.find((attr) => attr.id === "FRP")?.value || 1,
              10
            );
            return sum + Math.floor(item.quantity / incrementValue);
          }, 0);
  
          // Om antalet lådor uppnår buyX-värdet, applicera rabatten
          if (totalBoxes >= products[0].buyX) {
            const { buyX, payForY } = products[0]; // Anta att alla produkter har samma buyX och payForY
            const freeBoxes = Math.floor(totalBoxes / buyX) * (buyX - payForY);
            const discountPerBox =
              (freeBoxes * products[0].defaultPrice * parseInt(products[0].attributes?.find((attr) => attr.id === "FRP")?.value || 1, 10)) /
              totalBoxes;
  
            console.log(`Rabatt per låda: ${discountPerBox} SEK`);
  
            // Fördela rabatten proportionellt över produkterna
            eligibleItems.forEach((item) => {
              const incrementValue = parseInt(
                item.attributes?.find((attr) => attr.id === "FRP")?.value || 1,
                10
              );
              const boxCount = Math.floor(item.quantity / incrementValue);
  
              if (boxCount > 0) {
                const discountForItem = boxCount * discountPerBox;
  
                console.log(
                  `Produkt: ${item.description}, Lådor: ${boxCount}, Rabatt: ${discountForItem.toFixed(2)} SEK`
                );
  
                totalCampaignDiscount += discountForItem;
  
                // Lägg till rabattvärdet som ett nytt fält i produkten
                item.campaignDiscount = discountForItem;
              } else {
                // Om produkten inte längre kvalificerar sig, nollställ rabatt
                item.campaignDiscount = 0;
              }
            });
  
            console.log(`Total kampanjrabatt: ${totalCampaignDiscount.toFixed(2)} SEK`);
          } else {
            // Om inga produkter kvalificerar sig, nollställ kampanjrabatt för varje produkt
            eligibleItems.forEach((item) => {
              item.campaignDiscount = 0;
            });
          }
        }
      }
    });
  
    // Uppdatera kampanjrabatt
    setCampaignsDiscount(totalCampaignDiscount);
  
    console.log(`Total kampanjrabatt: ${totalCampaignDiscount.toFixed(2)} SEK`);
    return updatedCart;
  };
  
  
  
  
  
  
  
  
  
 // Beräkna enbart kampanjrabatten
const campaignDiscount = useMemo(() => {
  return cartItems.reduce((total, item) => {
    if (item.discount && item.discount > 0) {
      return total + item.discount; // Använd endast rabatten från kampanjer
    }
    return total;
  }, 0);
}, [cartItems]);

// Beräkna total rabatt (kampanjer + individuella rabatter)
const calculateTotalDiscount = (cartItems) => {
  let totalDiscount = 0;

  cartItems.forEach((item) => {
    if (
      item.discountedPrice !== null &&
      item.discountedPrice !== undefined &&
      !isNaN(item.discountedPrice)
    ) {
      const discount = item.defaultPrice - item.discountedPrice;
      totalDiscount += discount * item.quantity; // Multiplicera med antalet produkter
    }
  });

  // Lägg till kampanjrabatten
  const totalCampaignDiscount = campaignsDiscount || 0; // Hämta från context eller state
  totalDiscount += totalCampaignDiscount;

  console.log(
    `Total rabatt: ${totalDiscount} SEK (inkl. kampanjer: ${totalCampaignDiscount} SEK)`
  );

  return totalDiscount;
};

const totalDiscount = useMemo(() => calculateTotalDiscount(cartItems), [cartItems, campaignsDiscount]);


  // Beräkna totalsumma efter alla rabatter
  const getTotal = () => {
    return cartItems.reduce((total, item) => {
      const price = item.discountedPrice ?? item.defaultPrice;
      return total + price * item.quantity;
    }, 0) - totalDiscount;
  };
  


  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        isCombinedDelivery,
        setIsCombinedDelivery,
        getNonBestItemsTotal,
        getBestItemsTotal,
        getTotal,
        toggleCart,
        isCartOpen,
        setIsCartOpen,
        getShippingStatus,
        campaignDiscount,
        campaignsDiscount,
        bestItems,
        nonBestItems,
        removeItemCompletely,
        animation,
        totalDiscount,
        isProductListUpdate,
        setIsProductListUpdate
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
