const getOptimizedImageUrl = (url, size) => {
    return `${url}?alt=media&size=w${size}`;
  };
  
  const LazyImage = ({ src, alt, size = 800 }) => {
    const imageUrl = getOptimizedImageUrl(src, size);
  
    return (
      <img
        src={imageUrl}
        alt={alt}
        
        style={{ minHeight: '100px', minWidth: '100px',  objectFit: 'contain'}}
      />
    );
  };
  
  export default LazyImage;
  