import React, {  } from "react";
import { useParams } from 'react-router-dom';
import { useProductContext } from "../context/ProductContext";
import Products from "../Products/Products"; // Anta att du har en Products-komponent som visar en lista med produkter

const Kampanj = () => {
  const { discountCode } = useParams();
  const { kampanjProducts, loading, discounts } = useProductContext();

  if (loading) {
    return <p>Loading...</p>;
  }


  if (discountCode) {
    const discount = discounts.find(d => d.discountCode === discountCode);
    if (!discount) {
      return <p>Ingen kampanj funnen</p>;
    }

    return (<> {discountCode && kampanjProducts && (
      <div className="mt-20">
        <h1 className="text-4xl font-extrabold dark:text-white pt-5">{discount.description}</h1>
        <Products products={kampanjProducts.filter(product => product.discountCodes && product.discountCodes.includes(discountCode))} />
      </div>
      )}
      </>
    );
  }

  // Visa alla kampanjer om discountCode inte finns i params
  const promotionalDiscounts = discounts.filter(d => d.promotional && d.items && d.items.length > 0);
  return (
    <> {discountCode && (
    <div className="mt-20">
      {promotionalDiscounts.map(discount => (
        <div className='p-4 mt-20' key={discount.discountCode} >
          
      <h1 className="text-4xl font-extrabold dark:text-white">{discount.description}</h1>
         
          <Products products={kampanjProducts.filter(product => product.discountCodes && product.discountCodes.includes(discount.discountCode))} />
        </div>
        
      ))}
    </div>
    )}
    </>
  );
};

export default Kampanj;
