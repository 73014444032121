import React from 'react';
import NotfoundImg from '../imgs/404-bild-2.png';
import { Link } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
const NotFound = () => {
const { user} = useUserContext();


    return (
      <div className='container min-h-screen mt-24  flex-col flex items-center justify-center content-center'>
        <div className='w-2/3 mx-auto'><img src={NotfoundImg} alt='404' className='w-auto md:w-1/2 mx-auto dark:invert-1' /></div>
        <h1 className='text-4xl font-bold text-gray-800 py-3 dark:text-white '>Oops! Ser ut som att någon har ätit upp innehållet... </h1>
        <p className='text-lg text-gray-800 p-2 dark:text-white'>Servetten är fortfarande kvar, men tyvärr finns det inget kvar att servera här! </p>
          <p className='text-lg font-semibold  text-gray-800 p-2 dark:text-white'>Kanske kan du hitta något annat läckert på vår hemsida?</p>
      <div className='flex justify-center p-5'>
     {user && ( <Link to='/handla' className='bg-emerald-600 text-white px-4 py-2 rounded-md text-lg font-semibold hover:bg-emerald-700'>Handla</Link>
)}
        <Link to='/' className='bg-emerald-600 text-white px-4 py-2 rounded-md text-lg font-semibold hover:bg-emerald-700'>Till startsidan</Link>
</div>      </div>
    );
  };
  
  export default NotFound;