import React, { useEffect, useState, useRef } from 'react';
import { useProductContext } from '../context/ProductContext';
import { Link } from 'react-router-dom';
import { searchProducts } from '../services/searchUtils';

const SearchResults = React.memo(() => {
    const { products, searchQuery, setSearchQuery } = useProductContext();
    const [searchResults, setSearchResults] = useState([]);
    const resultsRef = useRef(null);
      // Uppdatera sökresultat och klasser baserat på searchQuery
      useEffect(() => {
        if (searchQuery) {
            const results = searchProducts(products, searchQuery);
            if (results.length === 0) {
                setSearchResults([]);
                if (resultsRef.current) {
                    resultsRef.current.classList.add("hidden");
                    resultsRef.current.classList.remove("block");
                }
            } else {
                setSearchResults(results.slice(0, 20));
                if (resultsRef.current) {
                    resultsRef.current.classList.add("block");
                    resultsRef.current.classList.remove("hidden");
                }
            }
        } else {
            setSearchResults([]);
            if (resultsRef.current) {
                resultsRef.current.classList.add("hidden");
                resultsRef.current.classList.remove("block");
            }
        }
    }, [searchQuery, products]);
    
   
    // Hantera klick utanför resultsRef
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resultsRef.current && !resultsRef.current.contains(event.target)) {
                setSearchResults([]);
                resultsRef.current.classList.add("hidden"); // Lägg till hidden
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [resultsRef]);

    return (
        <div ref={resultsRef} className='absolute  w-full top-20 flex-col flex max-h-72 overflow-y-auto search-results'>
            {searchResults.length > 0 ? (
                <table className='w-full text-sm text-left font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                    <thead>
                        <tr>
                            <th className='px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Produkt</th>
                            <th className='hidden md:block px-4 py-2 border-b border-gray-200 dark:border-gray-600'>Kategorier</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(product => (
                            <tr key={product.inventoryNumber} className='border-b border-gray-200 dark:border-gray-600'>
                                <td className='px-4 py-2'>
                                    <Link to={`/cat/${product.salesCategories[0].slug}/product/${product.inventoryNumber}`} onClick={() => setSearchQuery('')}>
                                        {product.description}
                                    </Link>
                                </td>
                                <td className='hidden md:block px-4 py-2'>
                                    {/* Lista alla kategorier produkten tillhör */}
                                    {product.salesCategories.map(category => (
                                        <Link
                                            key={category.slug}
                                            to={`/cat/${category.slug}`}
                                            onClick={() => setSearchQuery('')}
                                            className="mr-2"
                                        >
                                            {category.description}
                                        </Link>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="p-4 text-center text-gray-600 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:text-gray-300">
                    Tyvärr kunde vi inte hitta något på ditt sökord. Testa att söka på något annat.
                </p>
            )}
        </div>
    );
    
});

export default SearchResults;
