import React, { useState } from 'react';

function ProductDescription({ description }) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Max antal tecken innan vi visar "Läs mer"
  const maxLength = 264; // Kan justeras efter behov

  // Funktion för att hantera "Läs mer"/"Visa mindre"
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // Kortare beskrivning om texten överstiger maxLength
  const shortDescription = description?.length > maxLength ? description.slice(0, maxLength) + '...' : description;

  return (
    <div>
      <p className="text-gray-500 dark:text-gray-400">
        {isExpanded ? description : shortDescription}
      </p>
      {description.length > maxLength && (
        <button onClick={toggleDescription} className="text-blue-500">
          {isExpanded ? 'Visa mindre' : 'Läs mer'}
        </button>
      )}
    </div>
  );
}

export default ProductDescription;
