import { useUserContext } from "../context/userContext";

const DiscountNotification = () => {
  const { userData } = useUserContext();

  if (!userData || userData.discount === undefined || userData.discount <= 0) {
    return null;
  }

  return (
    <div className="pt-10 md:pt-0">
      <p className="rounded-md bg-green-100 px-1.5 py-2 text-lg text-center font-medium text-green-700">
        {userData.discount}% produktrabatt aktivt på hela sortimentet
      </p>
    </div>
  );
};

export default DiscountNotification;
