import React, { useState } from 'react';
import axios from 'axios';
import { updateDoc, doc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { authentication, db } from '../../firebase/firebase';

const LinkedAccounts = ({ customerId, linkedAccounts = [] }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [label, setLabel] = useState(''); // Fält för etikett
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [accounts, setAccounts] = useState(linkedAccounts);

  const handleAddAccount = async () => {
    setError('');
    setSuccess('');

    if (!email || !password) {
      setError('Både e-post och lösenord krävs.');
      return;
    }

    try {
      const response = await axios.post('/api/addSecondaryAccount', {
        email,
        password,
        label,
        customerId,
      });

      setSuccess(response.data.message);

      // Uppdatera listan över länkade konton
      setAccounts((prevAccounts) => [
        ...prevAccounts,
        { email, authUID: response.data.authUID, label },
      ]);

      // Återställ formuläret
      setEmail('');
      setPassword('');
      setLabel('');
    } catch (err) {
      console.error('Error adding secondary account:', err);
      setError(err.response?.data?.error || 'Ett fel uppstod vid tillägg av användaren.');
    }
  };

  const handleRemoveAccount = async (account) => {
    setError('');
    setSuccess('');

    try {
      const customerDocRef = doc(db, 'customers', customerId);

      await updateDoc(customerDocRef, {
        accounts: arrayRemove(account),
      });

      await axios.post('/api/logoutAccountEverywhere', {
        authUID: account.authUID,
      });

      setAccounts((prevAccounts) =>
        prevAccounts.filter((acc) => acc.authUID !== account.authUID)
      );

      setSuccess(`Kontot ${account.email} har tagits bort.`);
    } catch (err) {
      console.error('Error removing account:', err);
      setError('Ett fel uppstod vid borttagning av kontot.');
    }
  };

  const handlePasswordChange = async (authUID) => {
    setError('');
    setSuccess('');

    if (newPassword !== confirmPassword) {
      setError("Nya lösenordet matchar inte bekräftelselösenordet.");
      return;
    }

    try {
      // Reautenticera användaren
      const credential = EmailAuthProvider.credential(email, password);

      await reauthenticateWithCredential(authentication.currentUser, credential);

      // Uppdatera lösenordet
      await updatePassword(authentication.currentUser, newPassword);

      setSuccess("Lösenordet har uppdaterats framgångsrikt.");
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        setError("Om-inloggning krävs för att uppdatera lösenordet.");
      } else {
        setError("Ett fel uppstod vid uppdatering av lösenordet: " + error.message);
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mt-8">
      <h2 className="text-lg font-bold text-gray-900 dark:text-white">Länkade konton</h2>
      <p className='text-sm'>Har ni fler personer som behöver kunna beställa från samma konto? Lägg till länkade konton nedan.
      </p>
      <p className='text-sm font-semibold py-2'>Vad innebär det att länka ett konto?</p>
      <p className='text-sm py-2 leading-relaxed'> Ett länkat konto är en mailadress/lösenord som ni kopplar till ert primära kundkonto. När ett länkat konto skapats så kan användaren logga in på ert primära kundkonto med sin kombination av mailadress/lösenord. 
        Det är endast primära kontoinnehavare som kan hantera länkade konton. Sätt en etikett för att hålla reda på vems konto som är kopplat.
     </p>
      <ul className="mt-4 space-y-2">
        {accounts.map((account, index) => (
          <li
            key={index}
            className="p-2 bg-gray-100 dark:bg-gray-700 rounded-md flex justify-between items-center"
          >
            <div>
              <span>{account.email}</span>
              {account.label && <span className="ml-2 text-gray-500">({account.label})</span>}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleRemoveAccount(account)}
                className="text-red-500 hover:underline"
              >
                Ta bort
              </button>
              <button
                onClick={() => handlePasswordChange(account.authUID)}
                className="text-blue-500 hover:underline"
              >
                Ändra lösenord
              </button>
            </div>
          </li>
        ))}
      </ul>

      <div className="mt-6">
        <h3 className="text-sm font-semibold text-gray-900 dark:text-white">Lägg till nytt konto</h3>
        <div className="flex gap-4 mt-2">
          <input
            type="email"
            placeholder="E-post"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-1/3 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white p-2"
          />
          <input
            type="password"
            placeholder="Lösenord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-1/3 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white p-2"
          />
          <input
            type="text"
            placeholder="Etikett"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            className="w-1/3 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white p-2"
          />
        </div>
        <button
          onClick={handleAddAccount}
          className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md"
        >
          Lägg till konto
        </button>
        {success && <p className="text-green-500 mt-2">{success}</p>}
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default LinkedAccounts;
