import { Link} from "react-router-dom";
import React from "react";


const ProductBreadcrumb = ({ product, mainCategorySlug }) => {
  const salesCategory = product.salesCategories && product.salesCategories[0];
  const itemClass = product.itemClass;

  return (
    <nav className="m-auto flex p-5 mt-10 md:mt-0  text-sm md:text:md" aria-label="Breadcrumb">
      <ol className="inline-flex items-center m-auto md:m-0  md:flex-row space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center md:py-0 py-2">
          <Link
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            to="/"
          >
            <svg
              className="w-3 h-3 me-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
            </svg>
            Hem
          </Link>
        </li>
         {mainCategorySlug === 'klippvara' && (
          <li>
            <div className="flex items-center md:py-0 py-2">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link to="/klippvaror">
                Klippvaror
              </Link>
            </div>
          </li>
        )}
        {salesCategory && mainCategorySlug !== 'klippvara' && (
          <li>
            <div className="flex items-center md:py-0 py-2">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link to={`/cat/${salesCategory.slug}`}>
                {salesCategory.description}
              </Link>
            </div>
          </li>
        )}
        
       
        {itemClass && (
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link to={`/${itemClass.description}/${product.inventoryNumber}`}>
                {product.description}
              </Link>
            </div>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default ProductBreadcrumb;
