import React, { useEffect, useState, useMemo } from 'react';
import Card from '../components/Card';
import { useProductContext } from '../context/ProductContext';
import { v4 as uuidv4 } from 'uuid';

const SimilarProducts = React.memo(({ currentProduct, filterBySupplier }) => {
  const { discountedProducts, filterProducts, products } = useProductContext();
  const [similarProducts, setSimilarProducts] = useState([]);

  const filteredProducts = useMemo(() => {

// Välj vilken datakälla som ska användas, beroende på om användaren är inloggad eller inte
const availableProducts = discountedProducts.length > 0 ? discountedProducts : products;

    if (filterBySupplier && currentProduct.supplierDetails.length > 0) {
      // Filtrera produkter från samma leverantör
      const supplierName = currentProduct.supplierDetails[0].supplierName;
      return availableProducts.filter(product =>
        product.supplierDetails.some(supplier => supplier.supplierName === supplierName) &&
        product.inventoryNumber !== currentProduct.inventoryNumber
      );
    } else {
      // Filtrera produkter från samma underkategori baserat på den sista sluggen från salesCategories
      const subCategorySlug = currentProduct.salesCategories[currentProduct.salesCategories.length - 1]?.slug;
      if (subCategorySlug) {
        return filterProducts(null, null, subCategorySlug)
          .filter(product => product.inventoryNumber !== currentProduct.inventoryNumber);
      }
    }
    return [];
  }, [currentProduct, filterBySupplier, filterProducts, discountedProducts, products]);

  useEffect(() => {
    setSimilarProducts(filteredProducts);
  }, [filteredProducts]);

  if (similarProducts.length === 0) {
    return null;
  }

  return (
    <div className="mt-10">
      <div className="mt-6 grid  gap-y-10 gap-x-6 grid grid-cols-1 sm:grid-cols-2  md-grid-cols-3 lg:grid-cols-4 xl:gap-x-8">
        {similarProducts.map(product => (
          <Card key={uuidv4()} product={product} />
        ))}
      </div>
    </div>
  );
});

export default SimilarProducts;