import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { setDoc, doc, getDocs, deleteDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; 
import { searchProducts } from '../services/searchUtils'; 
import { useProductContext } from '../context/ProductContext';

const HighlightedProductsEditForm = ({ role }) => {
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [title, setTitle] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [description, setDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [productsInHighlight, setProductsInHighlight] = useState([]);
  const [slug, setSlug] = useState('');
  const [selectedHighlight, setSelectedHighlight] = useState(null); // För redigering av markerade produkter
  const { products, mainCategories } = useProductContext();
  const [selectedCategory, setSelectedCategory] = useState('');

  // Hämta markerade produkter från Firestore
  useEffect(() => {
    const fetchHighlighted = async () => {
      try {
        const highlightedRef = collection(db, 'highlightedContent');
        const highlightSnapshot = await getDocs(highlightedRef);
        const highlightData = highlightSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHighlightedProducts(highlightData);
      } catch (error) {
        console.error('Error fetching highlighted products:', error);
      }
    };

    fetchHighlighted();
  }, []);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  };

  useEffect(() => {
    setSlug(generateSlug(title));
  }, [title]);

  useEffect(() => {
    if (searchQuery) {
      const results = searchProducts(products, searchQuery);
      setSearchResults(results.slice(0, 10));
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, products]);

  const addProductToHighlight = (product) => {
    setProductsInHighlight([...productsInHighlight, product]);
    setSearchQuery('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = selectedHighlight ? selectedHighlight.id : uuidv4(); // Skapa eller uppdatera med befintligt ID

    try {
      const highlightDocRef = doc(db, 'highlightedContent', id);
      const updatedData = {
        title,
        bannerImage: bannerImage || '',
        description: description || '',
        slug,
        categorySlug: selectedCategory,
        products: productsInHighlight,
      };

      await setDoc(highlightDocRef, updatedData, { merge: true });

      alert('Highlighted products updated!');
      setHighlightedProducts((prev) => [
        ...prev.filter((highlight) => highlight.id !== id),
        { id, ...updatedData },
      ]);
      resetForm();
    } catch (error) {
      console.error('Error updating highlighted products:', error);
      alert('Error updating highlighted products');
    }
  };

  const handleEdit = (highlight) => {
    setTitle(highlight.title || '');
    setBannerImage(highlight.bannerImage || '');
    setDescription(highlight.description || '');
    setSlug(highlight.slug || '');
    setSelectedCategory(highlight.categorySlug || '');
    setProductsInHighlight(highlight.products || []);
    setSelectedHighlight(highlight); // Spara vilken produkt som redigeras
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'highlightedContent', id));
      setHighlightedProducts((prev) => prev.filter((highlight) => highlight.id !== id));
      alert('Highlighted product deleted!');
    } catch (error) {
      console.error('Error deleting highlighted product:', error);
      alert('Error deleting highlighted product');
    }
  };

  const resetForm = () => {
    setTitle('');
    setBannerImage('');
    setDescription('');
    setProductsInHighlight([]);
    setSelectedCategory('');
    setSelectedHighlight(null);
  };

  return role === 'admin' ? (
    <div className='mt-48 container mx-auto'>
      {/* Sektion för att lista och hantera aktiva markerade produkter */}
      <h2 className="text-2xl font-bold my-4">Aktiva Markerade Produkter:</h2>
      <div className="m-5 p-8 border-2">
        <ul>
          {highlightedProducts.map((highlight) => (
            <li key={highlight.id} className="my-2">
              <span>{highlight.title || highlight.description}</span>
              <button className="mx-2 text-blue-500" onClick={() => handleEdit(highlight)}>
                Edit
              </button>
              <button className="mx-2 text-red-500" onClick={() => handleDelete(highlight.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Formulär för att lägga till/uppdatera markerade produkter */}
      <h2 className="text-2xl font-bold my-4">Redigera Markerade Produkter:</h2>
      <form onSubmit={handleSubmit} className="mt-10">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
            Titel
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bannerImage">
            Banner Image URL
          </label>
          <input
            id="bannerImage"
            type="text"
            value={bannerImage}
            onChange={(e) => setBannerImage(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Beskrivning
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3"
            rows="4"
          />
        </div>

        {/* Välj kategori för markerade produkter */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
            Kategori (valfritt)
          </label>
          <select
            id="category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3"
          >
            <option value="">Ingen kategori vald</option>
            {mainCategories.map((category, index) => (
              <option key={index} value={category.slug}>
                {category.description}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Lägg till Produkt (Sök på artikelnummer eller produktnamn)
          </label>
          <input
            type="text"
            placeholder="Skriv produktens artikelnummer eller namn"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3"
          />
        </div>

        {searchResults.length > 0 && (
          <div className="mb-4">
            <ul>
              {searchResults.map((product, index) => (
                <li key={index}>
                  {product.description} (Artikelnummer: {product.inventoryNumber})
                  <button
                    type="button"
                    className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => addProductToHighlight(product)}
                  >
                    Lägg till
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {productsInHighlight.length > 0 && (
          <div>
            <h3>Tillagda Produkter:</h3>
            <ul>
              {productsInHighlight.map((product, index) => (
                <li key={index}>
                  {product.description} (Artikelnummer: {product.inventoryNumber})
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          {selectedHighlight ? 'Uppdatera Markerade Produkter' : 'Lägg till Markerade Produkter'}
        </button>

        {selectedHighlight && (
          <button
            type="button"
            onClick={resetForm}
            className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Avbryt
          </button>
        )}
      </form>
    </div>
  ) : null;
};

export default HighlightedProductsEditForm;
