import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
import { useProductContext } from '../context/ProductContext';
import { storage } from '../firebase/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import SpecialBanner from '../components/SpecialBanner';
import { Link } from 'react-router-dom';

const Klippvaror = ({ limit }) => {
    const { klippvaror } = useProductContext();
    const selectedSalesCategorySlug = 'klippvaror';
    const [bannerUrl, setBannerUrl] = useState(null);
    const placeholderBanner = 'https://fakeimg.pl/1280x560/cccccc/909090?text=Placeholder+1280x560';

    useEffect(() => {
        const fetchBannerUrl = async () => {
            try {
                const bannerRef = ref(storage, `categoryImage/${selectedSalesCategorySlug}.jpg`);
                const url = await getDownloadURL(bannerRef);
                setBannerUrl(url);
            } catch (error) {
                setBannerUrl(placeholderBanner);
            }
        };
    
        fetchBannerUrl();
    }, [selectedSalesCategorySlug]);

     // Sort products by expirationDate
     const sortedKlippvaror = klippvaror
     ? klippvaror.slice().sort((a, b) => {
           const dateA = new Date(a.expirationDate);
           const dateB = new Date(b.expirationDate);
           return dateA - dateB; // Sortera i stigande ordning
       })
     : [];

 if (!sortedKlippvaror || sortedKlippvaror.length === 0) {
     return null;
 }

    return (
        <div className='p-8 mt-20 pt-24'>
            <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to={`/cat/${selectedSalesCategorySlug}`}>Klippvaror</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <div
                className={`relative ${bannerUrl ? 'max-h-96 min-h-64' : ' p-10'} flex flex-col justify-center items-center text-center ${bannerUrl ? 'bg-cover bg-center' : ''}`}
                style={bannerUrl ? { backgroundImage: `url(${bannerUrl})` } : {}}
            >
                {bannerUrl && <div className="absolute inset-0 bg-black opacity-50"></div>}
                <h1 className={`relative z-10 text-4xl drop-shadow-2xl font-extrabold ${bannerUrl ? 'text-white' : 'text-gray-800'}`}>
                    KLIPPVAROR
                </h1>
                <p className={`text-lg z-10 py-5 drop-shadow-md ${bannerUrl ? 'text-white' : 'text-gray-800'}`}>
                    Klippvaror är varor vi rear ut till otroliga priser då de har korta datum.
                </p>
                <p className={`text z-10 py-2 px-5 mx-auto w-max transform hover:scale-110 hover:origin-center transition  duration-100 ease-in-out drop-shadow-lg text-center rounded ${bannerUrl ? 'bg-red-600 text-white' : 'text-gray-800'}`}>
                    <Link to="/klippvaror" >Se alla klippvaror</Link>
                </p>
            </div>
          
            <section className="card-container grid grid-cols-1 sm:grid-cols-2 md-grid-cols-3 lg:grid-cols-4 mt-10">
            {sortedKlippvaror.slice(0, limit).map((combinedProductData) => (
                    <Card
                        key={combinedProductData.inventoryNumber}
                        product={combinedProductData} // Skicka endast det kombinerade objektet som "product"
                    />
                ))}
            </section>
            <p className={`text z-10 py-2 px-5 mx-auto w-max text-center my-3 rounded ${bannerUrl ? 'bg-red-600 text-white' : 'text-gray-800'}`}>
                <Link to="/klippvaror">Se alla klippvaror</Link>
            </p>
        </div>
    );
};

export default Klippvaror;