import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto px-4 py-8 mt-24 pt-24">
            <h1 className="text-4xl font-bold mb-6">Allmänna Villkor</h1>
            <p className="text-gray-700 mb-2">Uppdaterad 2021-03-02</p>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Vid Beställning</h2>
                <p>Beställningar hos Gastronomileverantören AB kan göras på följande sätt:</p>
                <ul className="list-disc list-inside pl-6">
                    <li>Via vår hemsida: <a href="https://www.gastronomileverantoren.se" className="text-blue-600 hover:underline">www.gastronomileverantoren.se</a></li>
                    <li>Via email: <a href="mailto:info@gastronomileverantoren.se" className="text-blue-600 hover:underline">info@gastronomileverantoren.se</a></li>
                    <li>Via telefon: 0431 44 91 30 (Mån-Fre: 08.30-16.30)</li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Neka Köp</h2>
                <p>Vi förbehåller oss rätten att neka beställningar.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Beställning av Säsongsprodukter</h2>
                <p>Beställningar av säsongsprodukter (påsk, jul, Halloween, etc.) är bindande eftersom dessa varor beställs från våra leverantörer för specifika säsonger. Dessa produkter kan ha kortare bäst före-datum.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Priser & Moms</h2>
                <p>Priserna anges i svenska kronor, exklusive moms. Vi reserverar oss för prisändringar på grund av förändringar i moms, valutakurser eller inköpspriser.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Leveransvillkor</h2>
                <p>Fritt vårt lager (ex Works) Ängelholm, Sverige. Transportskador anmäls av kunden direkt till fraktbolaget.</p>
                <p>Fraktfritt vid köp över 6500 SEK netto exkl. moms inom Sverige. Vid export tillkommer frakt beroende på volym och vikt.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Minimiorder</h2>
                <p>Minimiorder är 2000 SEK netto exkl. moms.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Leveranstid</h2>
                <p>Leveranstiden är 2-4 arbetsdagar med reservation för slutförsäljning och förseningar hos fraktbolaget.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Fraktkostnad</h2>
                <p>Fraktkostnaden baseras på volym och beställningstyp. Maxavgift är 425 SEK.</p>
                <p>Vid glasförpackning krävs maxavgift på grund av krossrisk, vilket innebär att varorna levereras på pall.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Betalningsvillkor</h2>
                <p>Efterkrav/förskott alternativt 15 dagar netto för kreditgodkänd kund.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Kreditupplysning</h2>
                <p>Vid registrering av ny kund görs en kreditupplysning.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Export</h2>
                <p>Vid export gäller förskottsbetalning.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Öppet Köp</h2>
                <p>Vid öppet köp tillämpar vi distansavtalslagen, som ger kunden 14 dagars ångerrätt. Returnerade varor ska skickas tillbaka i oförändrat skick och obruten förpackning. Fraktkostnaden står kunden för.</p>
                <p>Kontakta oss gärna innan retur för assistans.</p>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Reklamation</h2>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Vid transportskada</h3>
                    <p>Vid skador vid leverans anmäls detta direkt till fraktbolagets chaufför.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Dold skada</h3>
                    <p>Vid dold skada kontakta oss omgående och bifoga bilder på skadan och emballage.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Chokladreklamation</h3>
                    <p>Chokladreklamation ska göras per telefon senast 2 dagar efter leverans.</p>
                </div>
            </section>

            <section className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">Övrig Information</h2>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Prisändringar</h3>
                    <p>Prisändringar på produkter kan förekomma. Se uppdaterade priser på hemsidan.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Chokladhantering</h3>
                    <p>Choklad förvaras bäst i 16-18°C och bör skyddas från solljus för att behålla aromen.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Personuppgiftshantering</h3>
                    <p>Vi hanterar personuppgifter enligt PUL. Läs vår <Link to="/integritetspolicy" className="text-blue-600 hover:underline">integritetspolicy här</Link>.</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-xl font-medium mb-2">Cookies</h3>
                    <p>Vi använder cookies för att underlätta köpprocessen. Cookies lagras i 1 månad och är krypterade för säkerhet.</p>
                </div>
            </section>
        </div>
    );
};

export default TermsAndConditions;
