import React from "react";

import Products from "./Products";
import { useProductContext } from "../context/ProductContext";
import SpecialBanner from "../components/SpecialBanner";
import { Link } from "react-router-dom";
const Sasong = ({ limit }) => {
    const { products, discountedProducts} = useProductContext();
    const selectedSalesCategory = 'Säsong';
    const selectedSalesCategorySlug = 'sasong';

    const productsToUse = discountedProducts.length > 0 ? discountedProducts : products;
    // Filtrera produkter baserat på både kategori och leverantör
    const filteredProducts = productsToUse.filter(product => {
    
      if (selectedSalesCategory) {
        return product.salesCategories.some(salesCategory => salesCategory.description === selectedSalesCategory);
      }
      // Om ingen kategori eller leverantör är vald, visa alla produkter
      else {
        return true;
      }
  
  
    });
     // Begränsa antalet produkter om limit-prop är satt
  const limitedProducts = limit ? filteredProducts.slice(0, limit) : filteredProducts;


    
    return (
      <div className='p-8 mt-20'>
          <nav className="flex p-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to={`/cat/${selectedSalesCategorySlug}`}>{selectedSalesCategory}</Link>
                        </div>
                    </li>
                </ol>
            </nav>
      <SpecialBanner slug={selectedSalesCategorySlug} />
      
        <h1 className="text-4xl font-extrabold dark:text-white">Säsongsprodukter</h1>
      <Products products={limitedProducts} />      
      </div>
    );
  };
  
  export default Sasong;