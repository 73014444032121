import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { getDocs, collection } from 'firebase/firestore';
import Products from '../Products/Products';
import { useProductContext } from '../context/ProductContext';
import { useUserContext } from '../context/userContext';
const HighlightedProducts = ({ categorySlug = null, showForLandingPage = false }) => {
  const { discountedProducts } = useProductContext();
  const [highlights, setHighlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useUserContext();
  const [highlightsUpdated, setHighlightsUpdated] = useState(false); // Flagga för att undvika oändlig loop
  
  
  useEffect(() => {
    const fetchHighlightedProducts = async () => {
        try {
            const highlightedRef = collection(db, 'highlightedContent');
            const snapshot = await getDocs(highlightedRef);
            const data = snapshot.docs.map((doc) => doc.data());

            // Filtrera markerade produkter baserat på kategori eller landningssida
            const filteredHighlights = data.filter(highlight => {
                if (showForLandingPage) {
                    return !highlight.categorySlug || highlight.categorySlug === '';
                } else if (categorySlug) {
                    return highlight.categorySlug === categorySlug;
                }
                return false;
            });

            setHighlights(filteredHighlights);
        } catch (error) {
            console.error('Error fetching highlighted products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    fetchHighlightedProducts();
}, [categorySlug, showForLandingPage]);

useEffect(() => {
  if (!highlightsUpdated && discountedProducts.length > 0) {
      // Uppdatera highlights-produkter med data från discountedProducts individuellt
      const updatedHighlights = highlights.map(highlight => ({
          ...highlight,
          products: highlight.products.map(product => {
              const discountedProduct = discountedProducts.find(dp => dp.inventoryNumber === product.inventoryNumber);
              return discountedProduct || product; // Om rabatterad produkt finns, använd den; annars behåll original
          }),
      }));
      setHighlights(updatedHighlights);
      setHighlightsUpdated(true); // Sätt flaggan till true för att undvika ytterligare körningar
  }
}, [discountedProducts, highlights, highlightsUpdated]);

  if (isLoading) {
    return <p>Laddar produkter...</p>;
  }

  if (highlights.length === 0) {
    return null;
  }

  return (
    <div>
        {highlights.map((highlight, index) => (
            <div key={index} className="highlight-section mt-5 p-4">
                <div
                    className={`relative ${highlight.bannerImage ? 'max-h-96 min-h-64' : 'p-10'} flex flex-col justify-center items-center text-center ${highlight.bannerImage ? 'bg-cover bg-center' : ''}`}
                    style={highlight.bannerImage ? { backgroundImage: `url(${highlight.bannerImage})` } : {}}
                >
                    {highlight.bannerImage && <div className="absolute inset-0 bg-black opacity-50"></div>}
                    <h1 className={`relative z-10 text-4xl drop-shadow-2xl font-extrabold ${highlight.bannerImage ? 'text-white' : 'text-gray-800'}`}>
                        {highlight.title}
                    </h1>
                    <p className={`text-xl drop-shadow-md z-10 py-5 ${highlight.bannerImage ? 'text-white' : 'text-gray-800'}`}>
                        {highlight.description}
                    </p>
                </div>
                {highlight.products && <Products products={highlight.products} />}
            </div>
        ))}
    </div>
);
};

export default HighlightedProducts;
