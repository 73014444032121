import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Almaregarden from '../imgs/6abcc08d-almaregarden-150x150.gif';
import Stubbs from '../imgs/43166ca8-stubbs-150x150.gif';
import Galantino from '../imgs/e9ab69a9-galantino-150x150.gif';
import Nordisk from '../imgs/7a87f9f3-nordisk-150x150.gif';
import MrsDarlington from '../imgs/903195da-mrs_darlingtons-150x150.gif';
import EnglishTeaShop from '../imgs/Ets.jpg';
import Goldkenn from '../imgs/a409f787-goldkenn-150x150.jpg';
import SimonColl from '../imgs/Logga-Simon-150x150.png';
import Gardiners from '../imgs/gardiners.gif';
import Venchi from '../imgs/venchi-150x150.jpg';
import Rubio from '../imgs/Rubio-150x150.png';


const LogoCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const logos = [
    Almaregarden,
    Galantino,
    Stubbs,
    Nordisk,
    MrsDarlington,
    Venchi,
    SimonColl,
    Gardiners,
    Goldkenn,
    EnglishTeaShop,
    Rubio,
    // Lägg till fler logotypvägar här
  ];

  return (
    <div className="container mx-auto p-4">
      
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="px-4">
            <img src={logo} alt={`Logo ${index + 1}`} className="mx-auto" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoCarousel;
